import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-select-android',
  templateUrl: './select-android.component.html',
  styleUrls: ['./select-android.component.css']
})
export class SelectAndroidComponent implements OnInit {
  form: FormGroup
  androidList = [];
  clone=[]
  searchAndroid:any;
  device:any;
    constructor(
      private dialogRef: MatDialogRef<SelectAndroidComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private firestore: AngularFirestore,
      private spinner: NgxSpinnerService,
      private dialog: MatDialog,
      private cd: ChangeDetectorRef,
      private toast: ToastrService,
     private fb: FormBuilder,

    ) {
      this.device = data;
      this.setup();
      // const AndroidControl = new FormControl();
      // const AndroidfilterControl = new FormControl();
      // AndroidfilterControl.valueChanges.subscribe(() => {
      //   this.findAndroid(AndroidfilterControl);
      // });
      // AndroidControl.valueChanges.subscribe(() => {
      //   if (AndroidControl.value) {
      //     this.searchAndroid = AndroidControl.value;
      //   }
      // });

      this.form =  this.fb.group({
        // AndroidControl: AndroidControl,
        // AndroidfilterControl : AndroidfilterControl,
        uuid: '',
      });
    }

    ngOnInit(): void {

    }

    async setup() {
      this.spinner.show();
      this.firestore.collection('devices').get().forEach(l => {
        l.forEach(ldoc => {
          const info={
            ...ldoc.data(),
            key:ldoc.id
          }
          this.androidList.push(info);
        });
        this.clone = this.androidList.slice();
        this.spinner.hide();

      });

    }

    findAndroid(raw) {
    if (!this.clone) { return; }
    const search = raw.value;
    this.androidList = this.clone.filter(p => p.deviceID.toLowerCase().includes(search.toLowerCase()));
  }

    cancel() {
      this.dialogRef.close(false);
    }

    confirm() {

      if(!this.form.get('uuid').value){
        this.toast.warning('No value', 'Please check')
        return;
      }

      const android = this.clone.find(p=> p.uuid === this.form.get('uuid').value)
      // if(!android){
      //   this.toast.warning('This uuid is not found', 'Please check')
      //   return;
      // }
      const dialogConfig = new MatDialogConfig();
      dialogConfig.height = 'auto';
      dialogConfig.width = '400px';
      const position = {
        top: '5%',
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      if(android){
        if(android.id){
          dialogConfig.data = {
            message: android.id + " already owns this android UUID" + '\n' +"Are you sure want overwrite this changes?" +'\n' +
            android.id + '->' +'not assigned \n' +
                     this.device.id + '->' + this.form.get('uuid').value
          }
        }else{
          dialogConfig.data = {
            message: 'Do you want to assign '+this.device.id+' to this android UUID? ' + this.form.get('uuid').value,
          }
        }
      }else{
        dialogConfig.data = {
          message: 'Do you want to assign '+this.device.id+' to this android UUID? ' + this.form.get('uuid').value,
        }
      }

      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
          if(result){
            this.spinner.show();
              const info={
                uuid : this.form.get('uuid').value
              }
              this.firestore.collection('devices').doc(this.device.id).update(info).then(r => {
                if(android){
                  if(android.id){
                    const blankInfo={
                      uuid : null,
                    }
                    this.firestore.collection('devices').doc(android.id).update(blankInfo).then(s=>{
                      this.spinner.hide();
                      this.dialogRef.close(true);
                    });
                  }else{
                    this.spinner.hide();
                    this.dialogRef.close(true);
                  }
                }else{
                  this.spinner.hide();
                  this.dialogRef.close(true);
                }

              });




          }
      });
    }


  }
