import { Component, OnInit } from '@angular/core';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  email = '';

  constructor(private au: AuthService) {
    au.currentEmail.subscribe(e => this.email = e);
  }

  ngOnInit() {
  }

}
