import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-assign-device',
  templateUrl: './assign-device.component.html',
  styleUrls: ['./assign-device.component.css']
})
export class AssignDeviceComponent implements OnInit {

  name: string;
  devicelist = [];
  sorteddevicelist= [];
  route:any;
  constructor(  private dialogRef: MatDialogRef<AssignDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,
    private  dialog : MatDialog,
    ) {
      this.route = data;
      this.name = data.RouteName;
      this.firestore.collection('devices').get().forEach(async (e) => {
        await e.forEach( table => {
          const data = {
            ...table.data(),
            id : table.id
          }
          this.devicelist.push(data);
          this.sorteddevicelist = this.devicelist.slice();
        });
      });
    }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.sorteddevicelist = this.devicelist.slice(0);
    this.sorteddevicelist = this.filterItems(this.sorteddevicelist, filterValue);
  }

  filterItems = (arr, query) => {
    return arr.filter(el => el.id.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }
  cancel(){
    this.dialogRef.close();
  }

  selectDevice(device){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Assign '+this.name+' to this device: ' + device.id + '?'
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.firestore.collection("DrawingRoutes").doc(this.route.id).update({belongDevice:device.id}).then(r=>{
        this.spinner.hide();
          this.dialogRef.close(device.id);
        })
      }
    })
  }
}
