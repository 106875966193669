import { CreateDeviceComponent } from './../create-device/create-device.component';
import { SelectAndroidComponent } from './select-android/select-android.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ManageLorryComponent } from '../../device-management/manage-lorry/manage-lorry.component';
import { DateService } from '../../Services/date.service';
import { FirestoreServiceService } from '../../Services/firestore-service.service';
import { UpdateDeviceComponent } from '../update-device/update-device.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  devicelist = [];
  sorteddevicelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };

  zones = [];

  @Input() role: string;


  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.SetupTable();

  }

  async SetupTable() {
    this.devicelist=[];
    this.spinner.show();
    await this.firestore.collection('devices', ref=>ref.where('role','==',this.role)).get().forEach(async (e) => {
      await e.forEach(table => {
          const info ={
            id: table.id,
            ...table.data()
          }
          this.devicelist.push(info);
          this.sorteddevicelist = this.devicelist.slice(this.offset, (this.offset + this.pageSize));
          this.length = this.devicelist.length;
      });
      this.spinner.hide();
    });

  }
  view(device){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data=device;
    this.dialog.open(UpdateDeviceComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.SetupTable();
      }
    });
  }



  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sorteddevicelist = this.devicelist.slice();
    this.sortDevice();
    this.limitDevice();
  }

  limitDevice() {
    this.sorteddevicelist = this.sorteddevicelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sorteddevicelist = this.devicelist.slice();
    if (this.searchValue) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sorteddevicelist = this.devicelist.slice();
      this.limitDevice();
      return;
    }

    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'role': return this.compare(a.role, b.role, isAsc);
        case 'android': return this.compare(a.AndroidID, b.AndroidID, isAsc);


        default: return 0;
      }
    });
    this.limitDevice();
  }


  sortDevice() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'role': return this.compare(a.role, b.role, isAsc);
        case 'android': return this.compare(a.AndroidID, b.AndroidID, isAsc);
     default: return 0;
      }
    });
  }

  refresh(){
    this.SetupTable();
  }


  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  dynamicSearch() {
    this.searchValue = this.searchbox;
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.zoneAssigned).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
    this.limitDevice();
  }

  DS() {
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.zoneAssigned).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
  }

  assign(device) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data=device;
    this.dialog.open(SelectAndroidComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.SetupTable();
      }
    });
  }

  delete(device){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

      dialogConfig.data = {
        message: 'Are you sure want to delete this device? ' +device.id,
      }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.firestore.collection('devices').doc(device.id).delete().then(e=>{
            this.spinner.hide();
            this.SetupTable();
        });
      }
    })
  }



}
