import { Component, ChangeDetectorRef, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';

// 3rd Party Libraries
import { MatDialogConfig, MatDialog } from '@angular/material';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  today = Date.now();
  navLinks: any[];
  activeLinkIndex = 0;
  myObserver: any;
  showScrollButton: boolean;
  PosToStartShowing = 1;
  noAskCloseTabAgain = false;
  authstate: any;

  panelOpenState = false;
  accessright = "";
  role: any;
  authser: any;

  private mobileQueryListener: () => void;
  flag = false;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private toast: ToastrService,
    private auth: AngularFireAuth,
    private authServ: AuthService


  ) {
    this.role = localStorage.getItem('Role');
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);

    const service = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.toast.warning('Session expired please login again')
        this.router.navigate(['/login']);
        service.unsubscribe()
      } else {
        this.authServ.SetEmail(authState.email)
        this.flag = true;

        service.unsubscribe()
      }
    })
  }

  expand = false;

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }

  sidebar(){
    this.expand = !this.expand;
  }


  logout(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {message:'Log out? '};

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate(['/login']);
        setTimeout(() => {
          this.angularFireAuth.auth.signOut();
        }, 1000);
      }
    })



  }

  settings(){
    this.router.navigate(['Home/Settings']);
  }

  getActive(value){
    if(value === this.router.url)
      return true
    return false
  }


}
