import { QrPopUpComponent } from './qr-pop-up/qr-pop-up.component';
import { AssignDeviceComponent } from './assign-device/assign-device.component';
import { ConfirmationDialogComponent } from './../../shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import * as firebase from 'firebase';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { CreateZoneComponent } from './create-zone/create-zone.component';
import { ListofstreetComponent } from './create-zone/listofstreet/listofstreet.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit, AfterViewInit {

  selectedIndex = 0;
  onFlag = false;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  geocoder: google.maps.Geocoder;
  listener: any;
  arealist = [];
  pathlist = [];
  savinglist = [];
  markerlist = [];
  pathSavedlist = [];
  role: any;
  path: any;
  word: any;
  form: FormGroup;
  clone = [];
  selectZoneMap: any


  tablestreetlist = [];
  sortedtablestreetlist: any;
  length = 0;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };

  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;

  zonelist = [];
  sortedzonelist: any;
  length2 = 0;
  pageSize2 = 25;
  pageSizeOptions2: number[] = [10, 25, 50, 100];
  pageIndex2 = 0;
  offset2 = this.pageSize2 * this.pageIndex2;
  searchbox2 = '';
  searchValue2 = '';
  sortedu2 = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator2', { read: MatPaginator, static: false }) topPaginator2: MatPaginator;
  @ViewChild('bottomPaginator2', { read: MatPaginator, static: false }) bottomPaginator2: MatPaginator;

  tableroutetlist = [];
  sortedtableroutetlist: any;
  lengthroute = 0;
  pageSizeroute = 25;
  pageSizeOptionsroute: number[] = [10, 25, 50, 100];
  pageIndexroute = 0;
  offsetroute = this.pageSizeroute * this.pageIndexroute;
  searchboxroute = '';
  searchValueroute = '';
  sorteduroute = {
    active: '',
    direction: ''
  };
  selectZone = "All"

  @ViewChild('topPaginator3', { read: MatPaginator, static: false }) topPaginator3: MatPaginator;
  @ViewChild('bottomPaginator3', { read: MatPaginator, static: false }) bottomPaginator3: MatPaginator;

  email = '';
  constructor(private dialog: MatDialog,
    private firestore: AngularFirestore,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private au: AuthService,
    private ngZone: NgZone) {
    this.role = localStorage.getItem('Role');

    au.currentEmail.subscribe(e=> this.email = e);

    if (this.role === 'PPS') {
      this.word = 'Route';
    } else {

      this.word = 'Street';

    }
  }
  clone1 = [];
  findRegion(value) {
    this.clone = this.clone1.filter(p => p.streetName.toLowerCase().includes(value.toLowerCase()));
  }

  filter2() {
    this.resetMap();
    let counter = 1;
    if(this.selectZoneMap === 'All')
      this.clone = this.tablestreetlist.slice();
    else
      this.clone = this.tablestreetlist.filter(e => e.belongZone === this.selectZoneMap);

    this.clone1 = this.clone.slice();
    this.clone.forEach(streetdoc => {
      const m = { ...streetdoc }
      const marker = new google.maps.Marker({
        ...m,
        position: new google.maps.LatLng(streetdoc.centerCoordinate.latitude,
          streetdoc.centerCoordinate.longitude),
        draggable: true
      });
      marker.setMap(this.map);
      const infoWindow = new google.maps.InfoWindow({
        content: '<span>Street name: ' + streetdoc.streetName + '</span><br>' +
          '</div><button id=window' + streetdoc.id + '>Remove </button>'
      });

      google.maps.event.addListener(infoWindow, 'domready', () => {
        document.getElementById('window' + streetdoc.id).addEventListener('click', () => {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%',
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;

          dialogConfig.data = {
            message: 'Do you want to remove: ' + streetdoc.streetName
          };
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
            if (result) {
              this.firestore
                .collection('streets')
                .doc(streetdoc.id)
                .delete();
              const r = this.markerlist.findIndex(m => m.id === streetdoc.id);

              this.markerlist[r].setMap(null);
              this.pathSavedlist[r].setMap(null);

              if (m.belongZone) {
                const zon = this.zonelist.find(r => r.name === m.belongZone);
                zon.addedStreet = zon.addedStreet.filter(s => s !== m.streetName);

              }
            }
          });

        });
      })
      marker.addListener('dragend', (e) => {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = 'auto';
        dialogConfig.width = 'auto';
        const position = {
          top: '5%',
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          message: 'Move here? ' + m.streetName
        };
        this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
          if (result) {
            this.firestore.collection('streets').doc(m.id)
              .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
          }
          else {
            const r = this.markerlist.findIndex(m => m.id === streetdoc.id);
            this.markerlist[r].setPosition(new google.maps.LatLng(m.centerCoordinate.latitude,
              m.centerCoordinate.longitude));
          }
        })

      });

      marker.addListener('click', () => {
        this.map.setZoom(19);
        this.map.setCenter(marker.getPosition());
        infoWindow.open(marker.getMap(), marker);
      });
      this.map.panTo(marker.getPosition());
      this.markerlist.push(marker);
      const paths = [];
      streetdoc.coordinates.forEach(coor => {
        paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
      });
      const path = new google.maps.Polyline({
        path: paths,
        strokeColor: 'red',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      path.setMap(this.map);
      this.pathSavedlist.push(path);
      if (counter === this.clone.length) {
        this.spinner.hide();
      }
      counter++;

    });
    const regionControl = new FormControl();
    const regionfilterControl = new FormControl();
    regionfilterControl.valueChanges.subscribe(() => {
      this.findRegion(regionfilterControl.value);
    });
    regionControl.valueChanges.subscribe(() => {
      if (regionControl.value) {
        const result = this.clone.find(e => e.streetName === regionControl.value);
        if (result) {
          console.log(result);

          const r = this.markerlist.find(m => m.id === result.id);
          this.map.panTo(r.getPosition());
          this.map.setZoom(18);
        }

      }
    });

    this.form = this.fb.group({
      regionControl,
      regionfilterControl,
    });
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.map.panTo(place.geometry.location);
        this.map.setZoom(18);

      });
    });

  }
  filter() {
    if (this.selectZone === 'All') {
      if (this.searchValue)
        this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
          String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()));
      else {
        this.sortedtablestreetlist = this.tablestreetlist.slice();
      }
    }
    else {
      if (this.searchValue)
        this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
          String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()) &&
          String(u.belongZone).toLowerCase().includes(this.selectZone.toLowerCase()));
      else {
        this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
          String(u.belongZone).toLowerCase().includes(this.selectZone.toLowerCase()));
      }
    }

    this.length = this.sortedtablestreetlist.length;
    this.sortStreet();
  }

  ngOnInit() {
    this.spinner.show();
    this.setup();
  }

  async setup() {
    this.zonelist = [];
    this.tablestreetlist = [];
    this.markerlist = [];
    this.pathSavedlist = [];
    if (this.role === 'KPA') {
      let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
      if(this.email === 'jppausm@vljejak.com')
        filter = ['USM MINDEN'];

      this.firestore.collection('zones', ref => ref.where('name','in',filter)).get().forEach(z => {
        z.forEach(doc => {
          const data = {
            name: doc.data().name,
            addedStreet: [],
            id: doc.id,
          }
          this.zonelist.push(data);
        })
        this.zonelist = this.zonelist.sort((a,b)=> {return (a.name < b.name ? -1 : 1) })
        this.sortedzonelist = this.zonelist.slice(this.offset, (this.offset + this.pageSize));
        this.length2 = this.zonelist.length;

        this.firestore.collection('streets', ref => ref.where('belongZone','in',filter).orderBy('streetName', 'asc')).get().forEach(streets => {
          // let counter = 1;
          // if (streets.size === 0) {
          //   this.spinner.hide();
          // }
          streets.forEach(streetdoc => {
            const info = {
              ...streetdoc.data(),
              mon: this.getCheck(streetdoc.data(), 1),
              tue: this.getCheck(streetdoc.data(), 2),
              wed: this.getCheck(streetdoc.data(), 3),
              thu: this.getCheck(streetdoc.data(), 4),
              fri: this.getCheck(streetdoc.data(), 5),
              sat: this.getCheck(streetdoc.data(), 6),
              sun: this.getCheck(streetdoc.data(), 7)
            }
            if (streetdoc.data().belongZone) {
              const zone = this.zonelist.find(z => z.name === streetdoc.data().belongZone);
              if (zone) {
                zone.addedStreet.push(streetdoc.data().streetName);
              }
            }
            this.tablestreetlist.push(info);
            this.sortedtablestreetlist = this.tablestreetlist.slice(this.offset, (this.offset + this.pageSize));
            this.length = this.tablestreetlist.length;

            // const m = { ...streetdoc.data() }
            // const marker = new google.maps.Marker({
            //   ...m,
            //   position: new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
            //     streetdoc.data().centerCoordinate.longitude),
            //   draggable: true
            // });
            // marker.setMap(this.map);
            // const infoWindow = new google.maps.InfoWindow({
            //   content: '<span>Street name: ' + streetdoc.data().streetName + '</span><br>' +
            //     '</div><button id=window' + streetdoc.data().id + '>Remove </button>'
            // });

            // google.maps.event.addListener(infoWindow, 'domready', () => {
            //   document.getElementById('window' + streetdoc.data().id).addEventListener('click', () => {
            //     const dialogConfig = new MatDialogConfig();
            //     dialogConfig.height = 'auto';
            //     dialogConfig.width = 'auto';
            //     const position = {
            //       top: '5%',
            //     };
            //     dialogConfig.position = position;
            //     dialogConfig.disableClose = true;
            //     dialogConfig.autoFocus = true;

            //     dialogConfig.data = {
            //       message: 'Do you want to remove: ' + streetdoc.data().streetName
            //     };
            //     this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
            //       if (result) {
            //         this.firestore
            //           .collection('streets')
            //           .doc(streetdoc.data().id)
            //           .delete();
            //         const r = this.markerlist.findIndex(m => m.id === streetdoc.data().id);
            //         console.log(this.markerlist.length)
            //         console.log(this.pathSavedlist.length)

            //         this.markerlist[r].setMap(null);
            //         this.pathSavedlist[r].setMap(null);

            //         if (m.belongZone) {
            //           const zon = this.zonelist.find(r => r.name === m.belongZone);
            //           zon.addedStreet = zon.addedStreet.filter(s => s !== m.streetName);

            //         }
            //       }
            //     });

            //   });
            // })
            // marker.addListener('dragend', (e) => {
            //   const dialogConfig = new MatDialogConfig();
            //   dialogConfig.height = 'auto';
            //   dialogConfig.width = 'auto';
            //   const position = {
            //     top: '5%',
            //   };
            //   dialogConfig.position = position;
            //   dialogConfig.disableClose = true;
            //   dialogConfig.autoFocus = true;

            //   dialogConfig.data = {
            //     message: 'Move here? ' + m.streetName
            //   };
            //   this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
            //     if (result) {
            //       this.firestore.collection('streets').doc(m.id)
            //         .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
            //     }
            //     else {
            //       const r = this.markerlist.findIndex(m => m.id === streetdoc.data().id);
            //       this.markerlist[r].setPosition(new google.maps.LatLng(m.centerCoordinate.latitude,
            //         m.centerCoordinate.longitude));
            //     }
            //   })

            // });

            // marker.addListener('click', () => {
            //   this.map.setZoom(19);
            //   this.map.setCenter(marker.getPosition());
            //   infoWindow.open(marker.getMap(), marker);
            // });
            // this.map.panTo(marker.getPosition());
            // this.markerlist.push(marker);
            // const paths = [];
            // streetdoc.data().coordinates.forEach(coor => {
            //   paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
            // });
            // const path = new google.maps.Polyline({
            //   path: paths,
            //   strokeColor: 'red',
            //   strokeOpacity: 1.0,
            //   strokeWeight: 2,
            // });
            // path.setMap(this.map);
            // this.pathSavedlist.push(path);
            // if (counter === streets.size) {
            //   this.spinner.hide();
            // }
            // counter++;

          });
          this.clone = this.tablestreetlist.slice();

          // const regionControl = new FormControl();
          // const regionfilterControl = new FormControl();
          // regionfilterControl.valueChanges.subscribe(() => {
          //   this.findRegion(regionfilterControl.value);
          // });
          // regionControl.valueChanges.subscribe(() => {
          //   if (regionControl.value) {
          //     const result = this.clone.find(e => e.streetName === regionControl.value);
          //     if (result) {
          //       const r = this.markerlist.find(m => m.id === result.id);
          //       this.map.panTo(r.getPosition());
          //       this.map.setZoom(18);
          //     }

          //   }
          // });

          // this.form = this.fb.group({
          //   regionControl,
          //   regionfilterControl,
          // });
          this.spinner.hide();
        });


      })
    }

  }

  on() {
    this.onFlag = true;
    this.listener = google.maps.event.addListener(this.map, 'click', event => {
      this.pathlist.push(event.latLng);
      this.savinglist.push(new firebase.firestore.GeoPoint(event.latLng.lat(), event.latLng.lng()));
      if (this.path) {
        this.path.setMap(null);
      }
      this.path = new google.maps.Polyline({
        path: this.pathlist,
        strokeColor: 'red',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      this.path.setMap(this.map);
    });

  }

  off() {
    this.onFlag = false;
    google.maps.event.removeListener(this.listener);
  }

  undo() {
    this.pathlist.pop();
    this.savinglist.pop();
    if (this.path) {
      this.path.setMap(null);
    }
    this.path = new google.maps.Polyline({
      path: this.pathlist,
      strokeColor: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.path.setMap(this.map);
  }

  clear() {
    this.pathlist = [];
    this.savinglist = [];



    if (this.path) {
      this.path.setMap(null);
    }
    this.path = new google.maps.Polyline({
      path: this.pathlist,
      strokeColor: 'red',
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });
    this.path.setMap(this.map);
  }

  resetMap() {
    for (var i = 0; i < this.markerlist.length; i++) {
      this.markerlist[i].setMap(null);
    }
    for (var i = 0; i < this.pathSavedlist.length; i++) {
      this.pathSavedlist[i].setMap(null);
    }

  }
  saveStreet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let message = '';
    if (this.role === 'PPS') {
      message = 'Add new route?';
      dialogConfig.data = {
        route: true,
        message
      };
    } else {
      message = 'Add new street?';
      dialogConfig.data = {
        name: true,
        message,
        zone: this.zonelist
      };
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.spinner.show();
        if (this.role === 'KPA') {
          let totallat = 0;
          let totallng = 0;
          this.pathlist.forEach((path, index) => {
            totallat += path.lat();
            totallng += path.lng();
            if (index === this.pathlist.length - 1) {
              totallat = totallat / this.pathlist.length;
              totallng = totallng / this.pathlist.length;
              const data = {
                coordinates: this.savinglist,
                streetName: result.name,
                centerCoordinate: new firebase.firestore.GeoPoint(totallat, totallng),
                dayAvailables: result.dayavailable,
                id: uuidv4(),
                belongZone: result.zone
              };
              const marker = new google.maps.Marker({
                ...data,
                position: new google.maps.LatLng(totallat, totallng),
                draggable: true
              });
              marker.setMap(this.map);

              const info = {
                ...data,
                mon: this.getCheck(data, 1),
                tue: this.getCheck(data, 2),
                wed: this.getCheck(data, 3),
                thu: this.getCheck(data, 4),
                fri: this.getCheck(data, 5),
                sat: this.getCheck(data, 6),
                sun: this.getCheck(data, 7)
              }
              this.tablestreetlist.push(info);
              this.sortedtablestreetlist = this.tablestreetlist.slice(this.offset, (this.offset + this.pageSize));
              this.length = this.tablestreetlist.length;

              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Street name: ' + result.name + '</span><br>' +
                  '</div><button id=window' + data.id + '>Remove </button>'
              });
              const zoneid = this.zonelist.find(z => z.name === result.zone);

              google.maps.event.addListener(infoWindow, 'domready', () => {
                document.getElementById('window' + data.id).addEventListener('click', () => {
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.height = 'auto';
                  dialogConfig.width = 'auto';
                  const position = {
                    top: '5%',
                  };
                  dialogConfig.position = position;
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;

                  dialogConfig.data = {
                    message: 'Do you want to remove: ' + data.streetName
                  };
                  this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                    if (result) {
                      this.firestore
                        .collection('streets')
                        .doc(data.id)
                        .delete();
                      const r = this.markerlist.findIndex(m => m.id === data.id);

                      this.markerlist[r].setMap(null);
                      this.pathSavedlist[r].setMap(null);
                      if (data.belongZone) {
                        const zon = this.zonelist.find(r => r.name === data.belongZone);
                        zon.addedStreet = zon.addedStreet.filter(s => s !== data.streetName);

                      }
                    }
                  });

                });
              })
              marker.addListener('dragend', (e) => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.height = 'auto';
                dialogConfig.width = 'auto';
                const position = {
                  top: '5%',
                };
                dialogConfig.position = position;
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;

                dialogConfig.data = {
                  message: 'Move here? ' + data.streetName
                };
                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                  if (result) {
                    this.firestore.collection('streets').doc(data.id)
                      .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
                  }
                  else {
                    const r = this.markerlist.findIndex(m => m.id === data.id);
                    this.markerlist[r].setPosition(new google.maps.LatLng(data.centerCoordinate.latitude,
                      data.centerCoordinate.longitude));
                  }
                })


              });

              marker.addListener('click', () => {
                this.map.setZoom(19);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });
              this.markerlist.push(marker);
              this.firestore.collection('streets').doc(data.id).set(data).then(result => {
                this.pathSavedlist.push(this.path);
                this.clear();
                this.redraw();
                this.spinner.hide();
              });
              if (zoneid) {
                zoneid.addedStreet.push(data.streetName);

              }

            }
          });
        } else if (this.role === 'PPS') {
          let totallat = 0;
          let totallng = 0;
          this.pathlist.forEach((path, index) => {
            totallat += path.lat();
            totallng += path.lng();
            if (index === this.pathlist.length - 1) {
              totallat = totallat / this.pathlist.length;
              totallng = totallng / this.pathlist.length;
              const data = {
                coordinates: this.savinglist,
                RouteName: result.route,
                centerCoordinate: new firebase.firestore.GeoPoint(totallat, totallng),
                id: uuidv4()
              };

              this.tableroutetlist.push(data);
              this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
              this.lengthroute = this.tableroutetlist.length;
              const marker = new google.maps.Marker({
                ...data,
                position: new google.maps.LatLng(totallat, totallng),
                draggable: true

              });
              marker.setMap(this.map);

              const infoWindow = new google.maps.InfoWindow({
                content: '<span>Route name:' + result.route + '</span><br>' +
                  '</div><button id=window' + data.id + '>Remove </button>'

              });

              google.maps.event.addListener(infoWindow, 'domready', () => {
                document.getElementById('window' + data.id).addEventListener('click', () => {
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.height = 'auto';
                  dialogConfig.width = 'auto';
                  const position = {
                    top: '5%',
                  };
                  dialogConfig.position = position;
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;

                  dialogConfig.data = {
                    message: 'Do you want to remove: ' + data.RouteName
                  };
                  this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                    if (result) {
                      this.firestore
                        .collection('DrawingRoutes')
                        .doc(data.id)
                        .delete();
                      const r = this.markerlist.findIndex(m => m.id === data.id);
                      this.markerlist[r].setMap(null);
                      this.pathSavedlist[r].setMap(null);
                      this.tableroutetlist = this.tableroutetlist.filter(t => t.id !== data.id);
                      this.sortedtableroutetlist = this.tableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
                      this.lengthroute = this.tableroutetlist.length;
                    }
                  });

                });
              })
              marker.addListener('click', () => {
                this.map.setZoom(19);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });

              marker.addListener('dragend', (e) => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.height = 'auto';
                dialogConfig.width = 'auto';
                const position = {
                  top: '5%',
                };
                dialogConfig.position = position;
                dialogConfig.disableClose = true;
                dialogConfig.autoFocus = true;

                dialogConfig.data = {
                  message: 'Move here? ' + data.RouteName
                };
                this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
                  if (result) {
                    this.firestore.collection('DrawingRoutes').doc(data.id)
                      .update({ centerCoordinate: new firebase.firestore.GeoPoint(e.latLng.lat(), e.latLng.lng()) })
                  }
                  else {
                    const r = this.markerlist.findIndex(m => m.id === data.id);
                    this.markerlist[r].setPosition(new google.maps.LatLng(data.centerCoordinate.latitude,
                      data.centerCoordinate.longitude));
                  }
                })


              });

              this.markerlist.push(marker);


              this.firestore.collection('DrawingRoutes').doc(data.id).set(data).then(result => {
                this.pathSavedlist.push(this.path);
                this.clear();
                this.redraw();
                this.spinner.hide();
              });
            }
          });
        }

      }
    });
  }

  redraw() {
    this.pathSavedlist[this.pathSavedlist.length - 1].setMap(this.map);
  }


  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedtablestreetlist = this.tablestreetlist.slice();

    if (this.searchValue) {
      this.DS();
    }
    this.sortStreet();
    this.limitStreet();
  }

  limitStreet() {
    this.sortedtablestreetlist = this.sortedtablestreetlist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedtablestreetlist = this.tablestreetlist.slice();
    if (this.searchValue) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sortedtablestreetlist = this.tablestreetlist.slice();
      this.limitStreet();
      return;
    }

    this.sortedtablestreetlist = this.sortedtablestreetlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.streetName, b.streetName, isAsc);
        case 'zone': return this.compare(a.belongZone, b.belongZone, isAsc);
        case 'monday': return this.compareDay(this.getCheck(a, 1), this.getCheck(b, 1), isAsc);
        case 'tuesday': return this.compareDay(this.getCheck(a, 2), this.getCheck(b, 2), isAsc);
        case 'wednesday': return this.compareDay(this.getCheck(a, 3), this.getCheck(b, 3), isAsc);
        case 'thursday': return this.compareDay(this.getCheck(a, 4), this.getCheck(b, 4), isAsc);
        case 'friday': return this.compareDay(this.getCheck(a, 5), this.getCheck(b, 5), isAsc);
        case 'saturday': return this.compareDay(this.getCheck(a, 6), this.getCheck(b, 6), isAsc);
        case 'sunday': return this.compareDay(this.getCheck(a, 7), this.getCheck(b, 7), isAsc);
        default: return 0;
      }
    });
    this.limitStreet();
  }

  sortStreet() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedtablestreetlist = this.sortedtablestreetlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'name': return this.compare(a.streetName, b.streetName, isAsc);
        case 'zone': return this.compare(a.belongZone, b.belongZone, isAsc);
        case 'monday': return this.compareDay(this.getCheck(a, 1), this.getCheck(b, 1), isAsc);
        case 'tuesday': return this.compareDay(this.getCheck(a, 2), this.getCheck(b, 2), isAsc);
        case 'wednesday': return this.compareDay(this.getCheck(a, 3), this.getCheck(b, 3), isAsc);
        case 'thursday': return this.compareDay(this.getCheck(a, 4), this.getCheck(b, 4), isAsc);
        case 'friday': return this.compareDay(this.getCheck(a, 5), this.getCheck(b, 5), isAsc);
        case 'saturday': return this.compareDay(this.getCheck(a, 6), this.getCheck(b, 6), isAsc);
        case 'sunday': return this.compareDay(this.getCheck(a, 7), this.getCheck(b, 7), isAsc);
        default: return 0;
      }
    });
  }
  dynamicSearch() {

    this.searchValue = this.searchbox;
    if (this.selectZone && this.selectZone !== 'All') {
      this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
        String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()) &&
        String(u.belongZone).toLowerCase().includes(this.selectZone.toLowerCase()));
    }

    else
      this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
        String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sortedtablestreetlist.length;
    this.sortStreet();
    this.limitStreet();
  }

  DS() {
    if (this.selectZone && this.selectZone !== 'All')
      this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
        String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()) &&
        String(u.belongZone).toLowerCase().includes(this.selectZone.toLowerCase()));
    else
      this.sortedtablestreetlist = this.tablestreetlist.filter(u =>
        String(u.streetName).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sortedtablestreetlist.length;
    this.sortStreet();
  }


  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareDay(a, b, isAsc) {
    return ((a === b) ? 0 : a ? -1 : 1) * (isAsc ? 1 : -1);
  }


  getCheck(street, day) {
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }

  SaveSchedule(street) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Save this schedule for this street: ' + street.streetName
    };
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        const dayavailable = [];
        if (street.mon) {
          dayavailable.push(1);
        }
        if (street.tue) {
          dayavailable.push(2);
        }
        if (street.wed) {
          dayavailable.push(3);
        }
        if (street.thu) {
          dayavailable.push(4);
        }
        if (street.fri) {
          dayavailable.push(5);
        }
        if (street.sat) {
          dayavailable.push(6);
        }
        if (street.sun) {
          dayavailable.push(7);
        }
        street.save = '';
        this.firestore.collection('streets').doc(street.id)
          .update({ dayAvailables: dayavailable }).then(s => {
            street.dayAvailables = dayavailable;
            this.toaster.success('Save sucessfully', street.streetName);
          });
      }
    });
  }

  changeEvent(event, street) {
    street.save = "<-Detect change";
  }


  paginator2(pageEvent: PageEvent) {
    this.pageSize2 = pageEvent.pageSize;
    this.pageIndex2 = pageEvent.pageIndex;
    this.offset2 = this.pageSize2 * this.pageIndex2;
    if (this.topPaginator2.pageIndex < this.pageIndex2) {
      this.topPaginator2.nextPage();
    } else if (this.topPaginator2.pageIndex > this.pageIndex2) {
      this.topPaginator2.previousPage();
    }
    if (this.bottomPaginator2.pageIndex < this.pageIndex2) {
      this.bottomPaginator2.nextPage();
    } else if (this.bottomPaginator2.pageIndex > this.pageIndex2) {
      this.bottomPaginator2.previousPage();
    }
    if (this.searchValue2) {
      this.DS2();
    }
    this.sortedzonelist = this.zonelist.slice();
    this.sortZone();
    this.limitZone();
  }

  limitZone() {
    this.sortedzonelist = this.sortedzonelist.slice(this.offset2, (this.offset2 + this.pageSize2));
  }

  sortData2(sort: Sort) {
    this.sortedu2 = sort;
    this.sortedzonelist = this.zonelist.slice();
    if (this.searchValue2) {
      this.DS2();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue2) {
      this.sortedzonelist = this.zonelist.slice();
      this.limitZone();
      return;
    }

    this.sortedzonelist = this.sortedzonelist.sort((a, b) => {
      const isAsc = this.sortedu2.direction === 'asc';
      switch (this.sortedu2.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'number': return this.compare(a.addedStreet.length, b.addedStreet.length, isAsc);
        default: return 0;
      }
    });
    this.limitZone();
  }

  sortZone() {
    if (!this.sortedu2.active || this.sortedu2.direction === '') {
      return;
    }
    this.sortedzonelist = this.sortedzonelist.sort((a, b) => {
      const isAsc = this.sortedu2.direction === 'asc';
      switch (this.sortedu2.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'number': return this.compare(a.addedStreet.length, b.addedStreet.length, isAsc);
        default: return 0;
      }
    });
  }
  dynamicSearch2() {
    this.searchValue2 = this.searchbox2;
    this.sortedzonelist = this.zonelist.filter(u =>
      String(u.name).toLowerCase().includes(this.searchValue2.toLowerCase()));
    this.length2 = this.sortedzonelist.length;
    this.sortZone();
    this.limitZone();
  }

  DS2() {
    this.sortedzonelist = this.zonelist.filter(u =>
      String(u.name).toLowerCase().includes(this.searchValue2.toLowerCase()));
    this.length2 = this.sortedzonelist.length;
    this.sortZone();
  }




  createZone() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    const model = {
      street: this.tablestreetlist,

    };

    dialogConfig.data = model;
    this.dialog.open(CreateZoneComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.setup();
      }
    });

  }

  editZone(zone) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = '80%';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    const model = {
      street: this.tablestreetlist,
      zone,
    };

    dialogConfig.data = model;
    this.dialog.open(CreateZoneComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.setup();
      }
    });
  }

  view(zone) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = zone.addedStreet;
    this.dialog.open(ListofstreetComponent, dialogConfig).afterClosed().subscribe(result => {

    })
  }


  paginator3(pageEvent: PageEvent) {
    this.pageSizeroute = pageEvent.pageSize;
    this.pageIndexroute = pageEvent.pageIndex;
    this.offsetroute = this.pageSizeroute * this.pageIndexroute;
    if (this.topPaginator3.pageIndex < this.pageIndexroute) {
      this.topPaginator3.nextPage();
    } else if (this.topPaginator3.pageIndex > this.pageIndexroute) {
      this.topPaginator3.previousPage();
    }
    if (this.bottomPaginator3.pageIndex < this.pageIndexroute) {
      this.bottomPaginator3.nextPage();
    } else if (this.bottomPaginator3.pageIndex > this.pageIndexroute) {
      this.bottomPaginator3.previousPage();
    }
    this.sortedtableroutetlist = this.tableroutetlist.slice();
    if (this.searchValueroute) {
      this.DS3();
    }
    this.sortRoute();
    this.limitRoute();
  }

  limitRoute() {
    this.sortedtableroutetlist = this.sortedtableroutetlist.slice(this.offsetroute, (this.offsetroute + this.pageSizeroute));
  }

  sortData3(sort: Sort) {
    this.sorteduroute = sort;
    this.sortedtableroutetlist = this.tableroutetlist.slice();
    if (this.searchValueroute) {
      this.DS3();
    }
    if (!sort.active || sort.direction === '' && !this.searchValueroute) {
      this.sortedtableroutetlist = this.tableroutetlist.slice();
      this.limitRoute();
      return;
    }

    this.sortedtableroutetlist = this.sortedtableroutetlist.sort((a, b) => {
      const isAsc = this.sorteduroute.direction === 'asc';
      switch (this.sorteduroute.active) {
        case 'name': return this.compare(a.RouteName, b.RouteName, isAsc);
        case 'device': return this.compare(a.belongDevice, b.belongDevice, isAsc);
        default: return 0;
      }
    });
    this.limitRoute();
  }

  sortRoute() {
    if (!this.sorteduroute.active || this.sorteduroute.direction === '') {
      return;
    }
    this.sortedtableroutetlist = this.sortedtableroutetlist.sort((a, b) => {
      const isAsc = this.sorteduroute.direction === 'asc';
      switch (this.sorteduroute.active) {
        case 'name': return this.compare(a.RouteName, b.RouteName, isAsc);
        case 'device': return this.compare(a.belongDevice, b.belongDevice, isAsc);
        default: return 0;
      }
    });
  }
  dynamicSearch3() {
    this.searchValueroute = this.searchboxroute;
    this.sortedtableroutetlist = this.tableroutetlist.filter(u =>
      String(u.name).toLowerCase().includes(this.searchValueroute.toLowerCase()));
    this.lengthroute = this.sortedtableroutetlist.length;
    this.sortRoute();
    this.limitRoute();
  }

  DS3() {
    this.sortedtableroutetlist = this.tableroutetlist.filter(u =>
      String(u.name).toLowerCase().includes(this.searchValueroute.toLowerCase()));
    this.lengthroute = this.sortedtableroutetlist.length;
    this.sortRoute();
  }


  AssignDevice(route) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    dialogConfig.disableClose = true;

    dialogConfig.data = route;
    this.dialog.open(AssignDeviceComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        route.belongDevice = result;
      }
    })
  }

  viewRoute(id, tab) {
    tab.selectedIndex = 0;
    const result = this.markerlist.find(m => m.id === id);
    this.map.panTo(result.position);
    this.map.setZoom(18);
  }

  viewStreet(id, tab) {
    tab.selectedIndex = 0;
    const result = this.markerlist.find(m => m.id === id);
    this.map.panTo(result.position);
    this.map.setZoom(18);
  }

  Qr(street) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: street.id,
      title: street.streetName
    }
    this.dialog.open(QrPopUpComponent, dialogConfig).afterClosed().subscribe(result => {

    })
  }



}
