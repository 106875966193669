import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import Chart from 'chart.js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-speed-graph',
  templateUrl: './speed-graph.component.html',
  styleUrls: ['./speed-graph.component.css']
})
export class SpeedGraphComponent implements AfterViewInit {

  speedGraph: any;

  device: any;
  constructor(
    public dialogRef: MatDialogRef<SpeedGraphComponent>,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,

    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.device = data;

  }

  ngAfterViewInit(): void {
    this.spinner.show();
    if (this.speedGraph) {
      this.speedGraph.destroy();
    }
    this.speedGraph = new Chart('speedgraph', {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: 'Blue',
            label: 'Speed (km/h)',
            fill: false
          },
        ]
      },
      options: {
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: 'x'
            },
            zoom: {
              enabled: true,
              mode: 'x'
            }
          }
        },
        legend: {
          display: true
        },

        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Period'
            },
            type: 'time',
            time: {
              unit: 'hour'
            },

          }],
          yAxes: [{
            display: true
          }],
        },
        responsive: true,
        maintainAspectRatio: false
      },


    });
    this.updateChart();
  }
  idle = [];
  updateChart() {
    this.firestore.collection('devices').doc(this.device.deviceId).collection('trips')
      .doc(this.device.id).collection('locations').get().forEach((g) => {
        var count = 1;
        var currenttime;
        var endtime;
        var flag = true;
        var nexttime = new Date();
        g.forEach(graphlabel => {
          if((!graphlabel.data().speed || graphlabel.data().speed === 0 ) && flag){
            currenttime = graphlabel.data().last_sync.toDate();
            nexttime = new Date(currenttime);
            nexttime.setMinutes(currenttime.getMinutes() + 15);
            flag = false;
          } else if (graphlabel.data().speed && !flag){
            if(graphlabel.data().speed > 0 ){
              if(g.docs[count].data().speed > graphlabel.data().speed){

              } else{

                if(nexttime.getTime() < graphlabel.data().last_sync.toDate().getTime()){
                  endtime = graphlabel.data().last_sync.toDate();

                }
              }
            }
          }
          if(currenttime && endtime){
            flag = true;
            const idletime = {
              starttime: currenttime,
              endtime
            }
            nexttime = null;
            currenttime = null;
            endtime = null;
            this.idle.push(idletime);
            console.log(this.idle);

          }

          const time = graphlabel.data().last_sync.toDate();
          this.speedGraph.data.labels.push(time);
          if (graphlabel.data().speed) {
            this.speedGraph.data.datasets[0].data.push((graphlabel.data().speed * 3.6).toFixed(2));
          } else {
            this.speedGraph.data.datasets[0].data.push(0);
          }
          this.speedGraph.update();
          if (count === g.size) {
            this.spinner.hide();
          }
          count++;

        })
      })
  }



  close() {
    this.dialogRef.close();
  }

}
