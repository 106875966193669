import { ConfirmationDialogComponent } from './../../../shared/confirmation-dialog/confirmation-dialog.component';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FirestoreServiceService } from '../../Services/firestore-service.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DateService } from '../../Services/date.service';
import { TripSelectComponent } from 'src/app/shared/trip-select/trip-select.component';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';
import { RouteSelectComponent } from 'src/app/shared/route-select/route-select.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private host: ElementRef<HTMLElement>,
    private toster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    // tslint:disable-next-line: deprecation
    this.localstorageDate = new Date(localStorage.getItem('Time'));
    this.dateSrv.currentDate.subscribe(date => this.selectedDate = date);
    this.role = localStorage.getItem('Role');

  }
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  localstorageDate: Date;
  @Input() deviceId: string;
  @Input() selectedDate: Date;
  @Input() DeleteID: string;

  SelectedDate: Date;
  speed: any;
  imageServices: any;
  trackingServices: any;
  role: any;
  trips = [];


  @Output() someEvent = new EventEmitter<any>();

  lorrylist = [];
  markerlist = [];
  positionlist = [];
  linelist = [];
  locatelist = [];
  savinglist = [];

  trackingPath: any;

  imagelist = [];
  ngOnInit(): void {
    this.SelectedDate = new Date(this.selectedDate);
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

    if (this.role === 'PPS') {
      this.MonitorInMap(this.deviceId);

    } else {
      this.GetKPAImage(this.deviceId);

    }
  }

  ngOnDestroy(): void {
    if (this.imageServices) {
      this.imageServices.unsubscribe();
    }
    if (this.trackingServices) {
      this.trackingServices.unsubscribe();
    }
  }

  destroy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Stop monitoring this id ' + this.deviceId + '?'
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.ngOnDestroy();
        this.callParent();
        this.host.nativeElement.remove();
      }
    })

  }

  callParent() {
    const StopTracking = {
      id: this.deviceId,
      date: this.SelectedDate,
      deleteID: this.DeleteID
    };
    this.someEvent.next(StopTracking);
  }

  MonitorInMap(deviceId) {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    var index = 1;
    this.selectedDate.setHours(0, 0, 0);
    const nextDate = new Date(this.selectedDate);
    nextDate.setDate(nextDate.getDate() + 1);
    var firstTime = true;
    this.firestore.collection('devices').doc(deviceId).collection('trips',
      ref => ref.where('date_start', '>=', this.selectedDate).
        where('date_start', '<', nextDate).
        orderBy('date_start', 'asc')).snapshotChanges().forEach((e) => {
          var counter = 1;
          this.spinner.hide();
          e.forEach(element => {
            var r = this.trips.findIndex(trip => trip.id === element.payload.doc.id);

            if (r !== -1 && element.type === 'modified') {
              this.trips[r] = {
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              };
              console.log(r);
            } else if (r === -1) {
              if (!firstTime) {
                this.toster.info('New trip deteted', 'Info');
              }
              this.trips.push({
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              });
              counter++;
            }
          });
          if (firstTime && this.trips.length > 0) {
            firstTime = false;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.height = 'auto';
            dialogConfig.width = 'auto';
            const position = {
              top: '5%',
            };
            dialogConfig.position = position;
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              trips: this.trips.slice(),
              deviceId: deviceId,
              date: this.SelectedDate

            };

            this.dialog.open(TripSelectComponent, dialogConfig).afterClosed().subscribe(result => {
              if (result) {
                const deviceRoute = [];
                this.firestore.collection('DrawingRoutes',ref=>ref.where('belongDevice','==',deviceId)).get().forEach(routes=>{
                  if(routes.size === 0){
                    this.StartTracking(result,null);
                    this.GetImage(deviceId, result.trip);
                  }
                  routes.forEach(r=>{
                    const info={
                      ...r.data()
                    }
                    deviceRoute.push(info);
                  })
                  dialogConfig.data = {
                    routes: deviceRoute.slice(),
                    deviceId,
                    date: this.SelectedDate
                  };
                  this.dialog.open(RouteSelectComponent, dialogConfig).afterClosed().subscribe(route => {
                    if(route){
                      this.StartTracking(result,route);
                      this.GetImage(deviceId, result.trip);
                    }
                  });
                })
              }
            });
          } else {
            this.toster.info('No trip found for device ' + deviceId, 'Info');
          }
        });
  }

  removePath(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  removeMarkers(marker) {
    marker.forEach(element => {
      element.setMap(null);
    });
  }
  StartTracking(device,route) {
    this.spinner.show();
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    var url = '';
    if (this.role === 'PPS') {
      url = 'https://img.icons8.com/dusk/64/000000/garbage-truck.png';
    } else {
      url = 'https://img.icons8.com/plasticine/100/000000/housekeeping.png';
    }
    if(route){
      this.removeSavedPath();
      this.pathSavedlist = [];
      this.firestore.collection('DrawingRoutes').doc(route).get().forEach(route=>{
        const paths = [];
          route.data().coordinates.forEach(coor => {
            paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
          });
          const path = new google.maps.Polyline({
            path: paths,
            strokeColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });
          path.setMap(this.map);
          this.pathSavedlist.push(path);
        });

    }

    this.firestore.collection('route', ref => ref.where('id', '==', device.id)
      .where('trip', '==', device.trip).where('deviceId', '==', device.deviceId.toString()))
      .get().forEach(route => {
        if (route.size > 0) {
          route.forEach(doc => {
            const locationLength = doc.data().coordinate.length;
            const mark = {
              position: new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
                doc.data().coordinate[locationLength - 1].longitude),
              map: this.map,
              icon: url,
              trip: device.trip,
              date: doc.data().date_complete.toDate(),
              id: device.deviceId
            };
            const marker = new google.maps.Marker({
              ...mark
            });
            this.lorrylist.push(marker);
            marker.setMap(this.map);
            this.markerlist.push(marker);
            const infoWindow = new google.maps.InfoWindow({
              content: '<span>Device ID: ' + mark.id + '</span><br>' +
                '<span>Trip: ' + mark.trip + '</span><br>'
            });
            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
            this.map.panTo(new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
              doc.data().coordinate[locationLength - 1].longitude));

            if (locationLength > 1) {
              const list = [];
              if (this.trackingPath) {
                this.trackingPath.setMap(null);
              }
              for (let i = 0; i < locationLength; i++) {
                list.push(new google.maps.LatLng(doc.data().coordinate[i].latitude,
                  doc.data().coordinate[i].longitude));
              }
              this.trackingPath = new google.maps.Polyline({
                path: list,
                strokeColor: 'Green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              });
              this.trackingPath.setMap(this.map);
              this.linelist.push(this.trackingPath);
              this.spinner.hide();
            }
          });
        } else {
          this.trackingServices = this.firestore.collection('devices').doc(device.deviceId).collection('trips')
            .doc(device.id).collection('locations').snapshotChanges().subscribe((l) => {
              console.log(l);
              l.forEach((location, index) => {
                const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
                if (!same) {
                  if (location.payload.doc.data().speed) {
                    this.speed = (location.payload.doc.data().speed * 3.6).toFixed(2) + 'km/h';
                  } else {
                    this.speed = '';
                  }
                  this.locatelist.push(location);
                  if (index === 0) {
                    const mark = {
                      position: new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude),
                      map: this.map,
                      icon: url,
                      trip: device.trip,
                      date: location.payload.doc.data().last_sync.toDate(),
                      id: device.deviceId
                    };
                    const marker = new google.maps.Marker({
                      ...mark
                    });
                    this.lorrylist.push(marker);
                    marker.setMap(this.map);
                    this.markerlist.push(marker);

                    const infoWindow = new google.maps.InfoWindow({
                      content: '<span>Device ID: ' + mark.id + '</span><br>' +
                        '<span>Trip: ' + mark.trip + '</span><br>'
                    });
                    marker.addListener('click', () => {
                      this.map.setZoom(18);
                      this.map.setCenter(marker.getPosition());
                      infoWindow.open(marker.getMap(), marker);
                    });
                    this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude));
                    this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude));
                  } else {
                    const r = this.lorrylist.find(m => m.id === device.deviceId && m.trip === device.trip);
                    if (r) {
                      r.date = location.payload.doc.data().last_sync.toDate();
                      r.setPosition(
                        new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude)
                      );
                      this.map.panTo(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude));
                      const SameThreshold = 0.1;

                      if (google.maps.geometry.spherical.computeDistanceBetween(this.positionlist[this.positionlist.length - 1],
                        new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude)) > SameThreshold) {
                        this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude));
                        this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude))
                      }

                      if (index === l.length - 1) {
                        if (device.date_complete) {
                          const data = {
                            coordinate: this.savinglist,
                            ...device
                          };
                          this.firestore.collection('route').add(data);
                        }
                        if (this.positionlist.length > 1) {
                          const list = [];
                          if (this.trackingPath) {
                            this.trackingPath.setMap(null);
                          }
                          for (let i = 0; i < this.positionlist.length; i++) {
                            list.push(this.positionlist[i]);
                          }
                          this.trackingPath = new google.maps.Polyline({
                            path: list,
                            strokeColor: 'Green',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          });
                          this.trackingPath.setMap(this.map);
                          this.linelist.push(this.trackingPath);
                          this.spinner.hide();
                        }
                      }
                    }
                  }
                  index++;
                }
              });
            });
        }
      });
  }
  pathSavedlist = [];
  GetKPAImage(deviceID) {
    this.pathSavedlist = [];
    const year = this.selectedDate.getFullYear();
    const day = ('0' + this.selectedDate.getDate()).slice(-2);
    const month = ('0' + (this.selectedDate.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.firestore.collection('ppsReports').doc(dateString).
      collection('reports', ref => ref.where('deviceId', '==', deviceID)
        .where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
          this.spinner.hide();

          result.forEach(e => {
            let info = {};
            const r = this.imagelist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
              i.choosedStreet === e.payload.doc.data().choosedStreet);

            if (r) {
              if (e.payload.doc.data().type === 'sebelum') {
                r.imagesebelum = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'semasa') {
                r.imagesemasa = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'selepas') {
                r.imageselepas = e.payload.doc.data().image;
              }
            } else {
              if (e.payload.doc.data().type === 'sebelum') {
                info = {
                  position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                    e.payload.doc.data().location.longitude),
                  latitude: e.payload.doc.data().location.latitude,
                  longitude: e.payload.doc.data().location.longitude,
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: e.payload.doc.data().image,
                  imageselepas: '../../../assets/notyet.png',
                  imagesemasa: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'semasa') {
                info = {
                  position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                    e.payload.doc.data().location.longitude),
                  latitude: e.payload.doc.data().location.latitude,
                  longitude: e.payload.doc.data().location.longitude,
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imageselepas: e.payload.doc.data().image,
                  imagesemasa: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'selepas') {
                info = {
                  position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                    e.payload.doc.data().location.longitude),
                  latitude: e.payload.doc.data().location.latitude,
                  longitude: e.payload.doc.data().location.longitude,
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imageselepas: '../../../assets/notyet.png',
                  imagesemasa: e.payload.doc.data().image,
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              }
            }
          });

          this.imagelist.forEach(e => {
            this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
              s.forEach(document => {
                e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                  document.data().centerCoordinate.longitude);
                const marker = new google.maps.Marker({ ...e });
                marker.setMap(this.map);
                this.map.setCenter(marker.getPosition());
                let infoWindow;
                const d = new Date(e.date);
                const hrs = d.getHours();
                const minute = d.getMinutes();
                const sec = d.getSeconds();
                let display;
                if (hrs > 12) {
                  display = hrs + ':' + minute + ':' + sec + 'pm';
                } else {
                  display = hrs + ':' + minute + ':' + sec + 'am';
                }
                infoWindow = new google.maps.InfoWindow({
                  content:
                    '<span>Waktu Operasi:' + display + ' </span><br><br>' +
                    '<span>Street Name:' + e.choosedStreet + ' </span><br><br>' +
                    '<span>Work:' + e.choosedWork + ' </span><br><br>' +
                    '<div class="row">' +
                    '<div class="col" >Sebelum:</div>' +
                    '<div class="col" >Semasa:</div>' +
                    '<div class="col" >Selepas:</div>' +
                    '</div>' +
                    '<div class="row">' +
                    '<div class="col"  style="width: 200px;height: 200px;"><img src=' + e.imagesebelum + ' width="100%" height="100%"></img></div>' +
                    '<div class="col"  style="width: 200px;height: 200px;"><img src=' + e.imagesemasa + ' width="100%" height="100%"></img></div>' +
                    '<div class="col"  style="width: 200px;height: 200px;"><img src=' + e.imageselepas + ' width="100%" height="100%"></img></div>' +
                    '</div>'
                });
                marker.addListener('click', () => {
                  this.map.setZoom(18);
                  this.map.setCenter(marker.getPosition());
                  infoWindow.open(marker.getMap(), marker);
                });

                const paths = [];
                document.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                const path = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                path.setMap(this.map);
                this.pathSavedlist.push(path);
                this.spinner.hide();
              })
            })
          });
        });

  }

  GetImage(deviceID, trip) {
    const year = this.selectedDate.getFullYear();
    const day = ('0' + this.selectedDate.getDate()).slice(-2);
    const month = ('0' + (this.selectedDate.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.imageServices = this.firestore.collection('ppsReports').doc(dateString).
      collection('reports', ref => ref.where('deviceId', '==', deviceID).
        where('trip', '==', trip).where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
          result.forEach(e => {
            let info = {
              position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                e.payload.doc.data().location.longitude),
              latitude: e.payload.doc.data().location.latitude,
              longitude: e.payload.doc.data().location.longitude,
              map: this.map,
              id: e.payload.doc.data().deviceId,
              date: e.payload.doc.data().date,
              imageurl: e.payload.doc.data().image,
              type: e.payload.doc.data().type,
            };
            this.imagelist.push(info);
          });
          console.log(this.imagelist);
          this.imagelist.forEach(e => {
            var marker;
            if (e.type === 'operasi') {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'

              });
            } else {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'

              });
            }
            marker.setMap(this.map);
            this.map.setCenter(marker.getPosition());
            var infoWindow;
            const d = new Date(e.date.toDate());
            const hrs = d.getHours();
            const minute = d.getMinutes();
            const sec = d.getSeconds();
            var display;
            console.log(e.type);
            if (hrs > 12) {
              display = hrs + ':' + minute + ':' + sec + 'pm';
            } else {
              display = hrs + ':' + minute + ':' + sec + 'am';
            }
            if (e.type === 'operasi') {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Operasi:' + display + ' </span><br><br>' +
                  '<div class="operasiFrame" style="width: 250px;height: 250px;border: 3px solid green;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            } else {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Aduan Halangan:' + display + ' </span><br><br>' +
                  '<div class="aduanFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            }

            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
          });
        });
  }
  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }

  tripSelect(t){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      trips: this.trips.slice(),
      devices: this.deviceId,
      date:this.selectedDate
    };

    const deviceRoute = [];
    this.firestore.collection('DrawingRoutes',ref=>ref.where('belongDevice','==',this.deviceId)).get().forEach(routes=>{
      if(routes.size === 0){
        this.StartTracking(t,null);
        this.GetImage(this.deviceId, t.trip);
      }
      routes.forEach(r=>{
        const info={
          ...r.data()
        }
        deviceRoute.push(info);
      })
      dialogConfig.data = {
        routes: deviceRoute.slice(),
        devices: this.deviceId,
      date:this.selectedDate
      };

      this.dialog.open(RouteSelectComponent, dialogConfig).afterClosed().subscribe(route => {
        if(route){
          this.StartTracking(t,route);
          this.GetImage(this.deviceId, t.trip);

        }
      });
    })
  }

}
