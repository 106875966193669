import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {

  datepicker = new Date();
  selection = 'Bulanan'
  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  overall=[{
    label:"Laporan Siap Kerja",
    percentage:"100%",
    color:"rgb(39 197 98)"
  },
  {
    label:"Jumlah Aduan",
    percentage:"71%",
    color:"#f28d06"
  },
  {
    label:"Aduan Selesai",
    percentage:"60%",
    color:"#f28d06"
  },
  {
    label:"Jumlah Pemantauan",
    percentage:"100%",
    color:"rgb(39 197 98)"
  },
  {
    label:"Pemantaun Selesai",
    percentage:"33%",
    color:"orangered"
  },
  {
    label:"Tindak Balas",
    percentage:"78%",
    color:"rgb(39 197 98)"
  }]

  display=[{
    label:"Laporan Siap Kerja",
    percentage:"100%",
    color:"rgb(39 197 98)"
  },
  {
    label:"Jumlah Aduan",
    percentage:"66%",
    color:"#f28d06"
  },
  {
    label:"Aduan Selesai",
    percentage:"55%",
    color:"#f28d06"
  },
  {
    label:"Jumlah Pemantauan",
    percentage:"100%",
    color:"rgb(39 197 98)"
  },
  {
    label:"Pemantaun Selesai",
    percentage:"20%",
    color:"orangered"
  },
  {
    label:"Tindak Balas",
    percentage:"90%",
    color:"rgb(39 197 98)"
  }]

  selectedDate: Date;
  zonelist=[];
  currentDate: Date;
  selectedDateMonth: Date;
  email = '';
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private au: AuthService,
    private spinner: NgxSpinnerService) {
      this.datepicker= new Date();
      this.selectedDate = new Date();
    au.currentEmail.subscribe(e=> this.email = e);
    this.currentDate = new Date();
      this.selectedDateMonth = new Date();
    }

  ngOnInit() {
    this.spinner.show();
    let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
    if(this.email === 'jppausm@vljejak.com')
      filter = ['USM MINDEN'];

    this.firestore.collection('zones',ref => ref.where('name','in',filter)).get().forEach(e => {
      e.forEach(doc => {
        this.zonelist.push(doc.data().name);
      })
      this.zonelist = this.zonelist.sort((a,b)=> {return (a.name < b.name ? -1 : 1) })

      this.spinner.hide();
    })
  }

  selectionChange(value){
    this.selection = value;
  }

  detectChanges(event) {
    this.selectedDateMonth = event;
  }

  view(item){

  }

  onSelect(event){
    this.selectedDate = event;
  }
  size = 1;

  page(value){
    this.size = value;
  }


  getCurrentMonth() {
    return  this.months[new Date().getMonth()];
  };


  getDaysInMonth(month = new Date().getMonth(), year = new Date().getFullYear()) {
    return  new Array(new Date(year, month, 0).getDate());
  };



}
