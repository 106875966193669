import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-zone',
  templateUrl: './create-zone.component.html',
  styleUrls: ['./create-zone.component.css']
})
export class CreateZoneComponent implements OnInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  streetlist = [];
  clone = [];
  selectedStreets = [];
  addedStreetss = [];
  selectedAddedPolicies = [];
  StreetPanel = false;
  disabled = true;
  SelectTextForSelectedPolicy: string;
  SelectTextForAddedPolicy: string;
  NameExisted = false;
  inputData: any;
  constructor(
    private dialogRef: MatDialogRef<CreateZoneComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,

  ) {

    this.streetlist = data.street;
    this.clone = this.streetlist.slice(0);
    this.firstFormGroup = this.formBuilder.group({
      selectedStreet: [],
      addedStreets: [],
    });
    this.secondFormGroup = this.formBuilder.group({
      name: ['', Validators.required],
    });
    this.SelectTextForSelectedPolicy = 'Select All';
    this.SelectTextForAddedPolicy = 'Select All';

    if(data.zone){
      this.inputData = data.zone;
      this.secondFormGroup.get('name').setValue(this.inputData.name);
      this.disabled = false;

      for (const street of this.inputData.addedStreet) {
        this.addedStreetss.push(this.clone.find(el => el.streetName === street));
      }

      for (const street of this.inputData.addedStreet) {
        this.clone = this.clone.filter(el => el.streetName !== street);
      }
    }
  }
  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  checkName(name) {

    if(this.inputData){
      return null;
    }
    return new Promise(resolve => {

      this.firestore.collection('zones',ref=>ref.where('name','==',name)).get().forEach(e=>{
        if(e.size > 0){
          resolve({ NameExisted: true });
        } else{
          resolve(null);

        }
      })
    });
  }

  selectAllForExistingPolicy(policies) {
    if (this.SelectTextForSelectedPolicy === 'Select All') {
      policies.selectAll();
    } else {
      policies.deselectAll();
    }
  }

  selectAllForAddedPolicy(addedPolicies) {

    if (this.SelectTextForAddedPolicy === 'Select All') {
      addedPolicies.selectAll();
    } else {
      addedPolicies.deselectAll();
    }
  }

  onExistedModelChange($event, policies) {

    this.selectedStreets = $event;
    if (policies.selectedOptions.selected.length > 0 && this.clone.length !== 0) {

      this.SelectTextForSelectedPolicy = 'Deselect All';
    } else {

      this.SelectTextForSelectedPolicy = 'Select All';
    }
  }

  onAddedModelChange($event, addedPolicies) {

    this.selectedAddedPolicies = $event;
    if (addedPolicies.selectedOptions.selected.length > 0 && this.addedStreetss.length !== 0) {

      this.SelectTextForAddedPolicy = 'Deselect All';
    } else {

      this.SelectTextForAddedPolicy = 'Select All';
    }
  }
  applyFilter(filterValue: string) {

    this.clone = this.streetlist.slice();
    this.clone = this.filterItems(this.clone, filterValue);

    for (const street of this.addedStreetss) {

      this.clone = this.clone.filter(el => el.streetName !== street.streetName);
    }
  }

  deleteSelectedPolicy() {

    for (const item of this.selectedAddedPolicies) {

      this.remove(this.addedStreetss, item);
    }

    this.SelectTextForAddedPolicy = 'Select All';

    this.clone = this.streetlist.slice();

    for (const street of this.addedStreetss) {

      this.clone = this.clone.filter(el => el.streetName !== street.streetName);
    }
  }

  filterItems = (arr, query) => {

    return arr.filter(el => el.streetName.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  remove(arr, value) {

    for (let i = 0; i < arr.length; i++) {

      if (arr[i] === value) {

        arr.splice(i, 1);
      }
    }

    if (this.addedStreetss.length > 0) {

      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }

  AddStreets() {

    if (this.selectedStreets.length === 0) {

      this.tosterservice.warning('Didn\'t select any streets', 'Warning');
      return;
    }

    for (const street of this.selectedStreets) {

      const result = this.addedStreetss.find(s => s.streetName === street.streetName);

      if (!result) {

        this.addedStreetss.push(street);
        this.clone = this.clone.filter(el => el.streetName !== street.streetName);
      }
    }

    if (this.addedStreetss.length > 0) {

      this.disabled = false;
    }

    this.SelectTextForSelectedPolicy = 'Select All';
  }

  async Finish() {

    this.spinner.show();
    const model = {
      name: this.secondFormGroup.get('name').value,
      addedStreet: [],
    };
    await this.addedStreetss.forEach(element => {

      model.addedStreet.push(element.streetName);
      this.firestore.collection('streets').doc(element.id).update({
        belongZone: this.secondFormGroup.get('name').value
      })
    });

    if(this.inputData){
      this.firestore.collection('zones').doc(this.inputData.id).update({
        name:this.secondFormGroup.get('name').value,
        addedStreet: model.addedStreet,
      }).then(()=>{
        this.spinner.hide();
        this.dialogRef.close(true);

      })
    }else{
      this.firestore.collection('zones').add(model).then(() => {
        this.dialogRef.close(true);
        this.spinner.hide();
        })
    }


  }

  async checkAvailability(stepper: MatStepper) {

    if (this.inputData) {
      stepper.next();
      return;
    }
    const result = await this.checkName(this.secondFormGroup.get('name').value);

    if (result === null) {
      this.NameExisted = false;
      stepper.next();
    } else {
      this.NameExisted = true;
      this.secondFormGroup.controls['name'].setErrors({ result });
    }
  }

}
