import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-overall-card',
  templateUrl: './overall-card.component.html',
  styleUrls: ['./overall-card.component.css']
})
export class OverallCardComponent implements OnInit {

  @Input() date: Date;
  values = {
    totalAduan: 0,
    totalAduanCompleted: 0,
    totalPemantauan: 0,
    totalPemantauanCompleted: 0,
    totalCompleted: 0,
    totalStreet: 0,
    RespondTime: 0,
  };

  email = '';
  boolflag= true;
  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private au: AuthService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    au.currentEmail.subscribe(e=> this.email = e);
    
  }

  ngOnInit() {
    this.getZoneInfo();

  }

  aduanGraph: Chart;
  pemantuanGraph: Chart;

  ngAfterViewInit(): void {
    this.initializeGraph();
   }

   initializeGraph(){

     if(this.aduanGraph){
       this.aduanGraph.destroy()
     }

     if(this.pemantuanGraph){
       this.pemantuanGraph.destroy()
     }

     this.aduanGraph = new Chart('pieAduan', {
       plugins: [ChartDataLabels],
       type: 'doughnut',
       data: {
         labels: ["Aduan Selesai", "Aduan Tak Selesai"],
         datasets: [{
           label: "Aduan",
           backgroundColor: ['#0000FF', '#0080FF'],
           data: [8,0],
         }]
       },
       options: {
         maintainAspectRatio:false,
         legend: {
           position: 'bottom'
         },
         plugins: {
           datalabels: {
             formatter: (value, ctx) => {
               if(value === 0)
                 return "";
               return value;
             },
             color: '#fff',
           }
         }
       }
     })

     this.pemantuanGraph = new Chart('piePemantuan', {
       plugins: [ChartDataLabels],
       type: 'doughnut',
       data: {
         labels: ["Pemantuan Selesai", "Pemantuan Tak Selesai"],
         datasets: [{
           label: "Aduan",
           backgroundColor: ['#00994C', '#00CC66'],
           data: [10,2],
         }]
       },
       options: {
         maintainAspectRatio:false,
         legend: {
           position: 'bottom'
         },
         plugins: {
           datalabels: {
             formatter: (value, ctx) => {
               if(value === 0)
                 return "";
               return value;
             },
             color: '#fff',
           }
         }
       }
     })



   }
   getColors(value) {
    if (value > 66) {
      return "#5fe2a0";
    } else if (value > 33) {
      return "#ffed06"
    } else {
      return "#f53c56"
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getZoneInfo();
  }
  async getZoneInfo() {
    this.values = {
      totalAduan: 0,
      totalAduanCompleted: 0,
      totalPemantauan: 0,
      totalPemantauanCompleted: 0,
      totalCompleted: 0,
      totalStreet: 0,
      RespondTime: 0,
    }
    this.spinner.show();
    if (!this.date)
      this.date = new Date();
    let day = this.date.getDay();
    if (day === 0)
      day = 7;


      let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
      if(this.email === 'jppausm@vljejak.com')
        filter = ['USM MINDEN'];
    await this.firestore.collection('streets', ref => ref.where('belongZone','in',filter).orderBy('streetName', 'asc')).get().forEach(async streets => {
      this.values.totalStreet = 0;
      await streets.forEach(streetdoc => {
        this.getCheck(streetdoc.data(), day);
      });
      this.spinner.hide();
    });

    const d = new Date(this.date);
    this.check(d);
    this.getAduan(d);
    this.getCompleted(d);
  }

  getAduan(d) {
    const date = new Date(d);
    date.setHours(0, 0, 0);
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);


    this.firestore.collection('inspects', ref => ref.where('date', '>=', date).where('date', '<=', nextDate)).get().forEach(async aduan => {
      await aduan.forEach(doc => {
        if (doc.data().category = 'aduan')
          this.values.totalAduan++;
        else
          this.values.totalPemantauan++;
      });
      this.spinner.hide();
    });
  }

  getCompleted(d) {
    const date = new Date(d);
    date.setHours(0, 0, 0);
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    console.log(date);

    this.firestore.collection('inspectArchives', ref => ref.where('date', '>=', date).where('date', '<=', nextDate)).get().forEach(async aduan => {
    console.log(aduan.size);

      await aduan.forEach(doc => {
        if (doc.data().category = 'aduan') {
          this.values.totalAduan++;
          this.values.totalAduanCompleted++;
        }
        else {
          this.values.totalPemantauan++;
          this.values.totalPemantauanCompleted++;
        }
      });
      this.spinner.hide();
    });
  }

  imagelist = [];

  getCheck(street, day) {
    street.dayAvailables.forEach(element => {
      if (day === element)
        this.values.totalStreet++;
    });
  }

  getColor(value1, value2) {
    if (value1 === 0 || !value1)
      return "none";

    const value = value1 / value2 * 100;
    if (value < 33) {
      return "orangered";
    } else if (value < 66) {
      return "#f28d06"
    } else {
      return "rgb(39 197 98)"
    }

  }
  getColor2(value1) {
    if (value1 === 0 || !value1)
      return "none";

    return "rgb(39 197 98)"
  }

  count = [];
  check(date) {
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    console.log(dateString);
    this.firestore.collection('reports').doc(dateString).get().forEach(r => {
      const re = r.data()['CompletedAll']
      if ((re || re === 0) && !(
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
      )) {
        this.values.totalCompleted = r.data()['CompletedAll'];
      } else {
        this.firestore.collection('reports').doc(dateString).
          collection('reports', ref => ref.where('mode', '==', 'kpa')).get().forEach(result => {
            var counter = 0;
            this.imagelist = [];
            result.forEach(e => {
              let info = {};
              const r = this.imagelist.find(i => i.choosedWork === e.data().choosedWork &&
                i.choosedStreet === e.data().choosedStreet);
              if (r) {
                if (e.data().type === 'sebelum') {
                  r.imagesebelum = e.data().image;
                } else if (e.data().type === 'semasa') {
                  r.imagesemasa = e.data().image;
                } else if (e.data().type === 'selepas') {
                  r.imageselepas = e.data().image;
                }
              } else {
                if (e.data().type === 'sebelum') {
                  info = {
                    imagesebelum: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'semasa') {
                  info = {
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'selepas') {
                  info = {
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    imageselepas: e.data().image,
                    choosedStreet: e.data().choosedStreet,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                }
              }

            });

            this.imagelist = this.imagelist.sort((a, b) => {
              return this.compare(a.choosedStreet, b.choosedStreet, true);
            })
            this.spinner.hide();
            console.log(this.imagelist);
            this.imagelist.forEach(async (e, index) => {
              if (e.imagesebelum === '../../../assets/notyet.png'
              || e.imageselepas === '../../../assets/notyet.png'
              || e.imagesemasa === '../../../assets/notyet.png') {
                return;
              }
              if (index >= 1) {
                if (e.choosedStreet === this.imagelist[index - 1].choosedStreet) {
                  return;
                }
              }
              counter++;
            });
            this.values.totalCompleted = counter;
            const completed = {}
            completed['CompletedAll'] = counter

            this.firestore.collection('reports').doc(dateString).update(completed);
          });
      }
    })


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  view() {

  }

}
