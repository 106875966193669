import { RouteSelectComponent } from './../../shared/route-select/route-select.component';
import { element } from 'protractor';
import { TripSelectComponent } from './../../shared/trip-select/trip-select.component';
import { ToastrService } from 'ngx-toastr';
import { DateService } from './../Services/date.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatPaginator, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { FirestoreServiceService } from '../Services/firestore-service.service';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { AddRouteComponent } from './add-route/add-route.component';
import { DeviceInfoModalComponent } from 'src/app/shared/device-info-modal/device-info-modal.component';
import { AngularFireStorageReference, AngularFireStorage } from '@angular/fire/storage';
import Chart from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { MonitorDatePickerComponent } from 'src/app/shared/monitor-date-picker/monitor-date-picker.component';
import 'chartjs-plugin-zoom';
import 'hammerjs';
import { AuthService } from '../Services/auth.service';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements AfterViewInit, OnInit {

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private au: AuthService,

  ) {
    au.currentEmail.subscribe(e=> this.email = e);
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this.mobileQueryListener);
    this.role = localStorage.getItem('Role');
    if (this.role === 'KPA') {
      this.minimizeFlag = true;
    }
    this.maxDate= new Date();

  }

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  selectedIndex = 1;
  speedGraph: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  isTracking = false;
  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  devicelist = [];
  sorteddevicelist: any;
  sortedzonelist: any;
  zonelist = []
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  markerlist = [];
  linelist = [];
  positionlist = [];
  lorrylist = [];
  imagelist = [];
  counter = 0;
  speed: any;
  role: any;
  devices: any;
  trips = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;
  identifyCounterTrip = 1;
  minimizeFlag = false;
  minimizeGraphFlag = false;
  maplist = [];
  deletecounter = 0;
  receiptlist = [];
  locatelist = [];
  savinglist = [];
  trackingPath: any;
  mode = 'single';
  pathSavedlist = [];
  maxDate:Date;
  ngOnInit(): void {
    const geocoder = new google.maps.Geocoder();
    this.SetupTable(geocoder);
    if(this.role === 'KPA'){
      this.GetKPAImage(this.kpaDate);
    }

    this.spinner.show();
  }
  email = '';


  saveRoute() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(AddRouteComponent, dialogConfig).afterClosed().subscribe(result => { });
  }

  removePath(path) {
    path.forEach(element => {
      element.setMap(null);
    });
  }

  removeMarkers(marker) {
    marker.forEach(element => {
      element.setMap(null);
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
    if(this.service){
      this.service.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    const d = new Date();
    d.setHours(0);
    const e = new Date();

    if (this.role === 'PPS') {
      if (this.speedGraph) {
        this.speedGraph.destroy();
      }
      this.speedGraph = new Chart('graph', {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              borderColor: 'Blue',
              label: 'Speed (km/h)',
              fill: false
            },
          ]
        },
        options: {
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x'
              },
              zoom: {
                enabled: true,
                mode: 'x'
              }
            }
          },
          legend: {
            display: true
          },

          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Period'
              },
              type: 'time',
              time: {
                unit: 'hour'
              },

            }],
            yAxes: [{
              display: true
            }],
          },
          responsive: false,
          maintainAspectRatio: false
        },



      });

    }


    this.coordinates = new google.maps.LatLng(this.lat, this.lng);

    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);

  }

  async SetupTable(geocoder: google.maps.Geocoder) {
    if (this.role === 'PPS') {
      await this.firestore.collection('devices').snapshotChanges().forEach(async (e) => {
        await e.forEach(async (table: any, index) => {
          const r = this.devicelist.find(d => d.id === table.payload.doc.id);
          if (!r) {
            await geocoder.geocode(
              { location: new google.maps.LatLng(table.payload.doc.data().location.latitude, table.payload.doc.data().location.longitude) },
              (
                results: google.maps.GeocoderResult[],
                status: google.maps.GeocoderStatus
              ) => {
                if (status === 'OK') {
                  if (results[0]) {
                    const d = {
                      id: table.payload.doc.id,
                      location: results[0].formatted_address
                    };
                    this.devicelist.push(d);
                    this.sorteddevicelist = this.devicelist.slice();
                  } else {
                    const d = {
                      id: table.payload.doc.id,
                      location: ''
                    };
                    this.devicelist.push(d);
                    this.sorteddevicelist = this.devicelist.slice();
                  }
                } else {
                  const d = {
                    id: table.payload.doc.id,
                    location: ''
                  };
                  this.devicelist.push(d);
                  this.sorteddevicelist = this.devicelist.slice();


                }
              }
            );
          }
          if (index === e.length - 1) {
            this.spinner.hide();
          }


        });
      });


    } else if (this.role === 'KPA') {
      let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
      if(this.email === 'jppausm@vljejak.com')
        filter = ['USM MINDEN'];


      this.firestore.collection('zones',ref => ref.where('name','in',filter)).get().forEach(e => {
        e.forEach(doc => {
          this.zonelist.push(doc.data());
        })
        this.zonelist = this.zonelist.sort((a,b)=> {return (a.name < b.name ? -1 : 1) })
        this.sortedzonelist = this.zonelist.slice();

      })
    }

  }

  MonitorInMap(deviceId, date) {
    this.spinner.show();
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    date.setHours(0, 0, 0);
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    let firstTime = true;
    this.trips = [];
    this.firestore.collection('devices').doc(deviceId).collection('trips',
      ref => ref.where('date_start', '>=', date).
        where('date_start', '<', nextDate).
        orderBy('date_start', 'asc')).snapshotChanges().forEach((e) => {
          let counter = 1;
          this.spinner.hide();

          e.forEach(element => {
            const r = this.trips.findIndex(trip => trip.id === element.payload.doc.id);
            if (r !== -1 && element.type === 'modified') {
              this.trips[r] = {
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              };
            } else if (r === -1) {
              if (!firstTime) {
                this.toaster.info('New trip deteted', 'Info');
              }
              this.trips.push({
                ...element.payload.doc.data(),
                id: element.payload.doc.id,
              });
              counter++;
            }
          });
          if (firstTime && this.trips.length > 0) {
            firstTime = false;
            const dialogConfig = new MatDialogConfig();
            dialogConfig.height = 'auto';
            dialogConfig.width = '50%';
            const position = {
              top: '5%',
            };
            dialogConfig.position = position;
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;

            dialogConfig.data = {
              trips: this.trips.slice(),
              deviceId,
              date
            };

            this.dialog.open(TripSelectComponent, dialogConfig).afterClosed().subscribe(result => {
              if (result) {
                const deviceRoute = [];
                this.firestore.collection('DrawingRoutes',ref=>ref.where('belongDevice','==',deviceId)).get().forEach(routes=>{
                  if(routes.size === 0){
                    this.StartTracking(result,null);
                    this.GetImage(deviceId, date, result.trip);
                  }
                  routes.forEach(r=>{
                    const info={
                      ...r.data()
                    }
                    deviceRoute.push(info);
                  })
                  dialogConfig.data = {
                    routes: deviceRoute.slice(),
                    deviceId,
                    date
                  };
                  this.dialog.open(RouteSelectComponent, dialogConfig).afterClosed().subscribe(route => {
                    if(route){
                      this.StartTracking(result,route);
                      this.GetImage(deviceId, date, result.trip);
                    }
                  });
                })
              }
            });
          } else {
            this.toaster.info('No trip found', 'Info');
          }
        });
  }
  StartTracking(device,route) {

    this.spinner.show();
    this.locatelist = [];
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];

    if (!this.minimizeGraphFlag && this.role === 'PPS') {
      this.speedGraph.data.datasets[0].data = [];
      this.speedGraph.data.labels = [];
      this.speedGraph.update();
    }
    let url = '';
    if (this.role === 'PPS') {
      url = 'https://img.icons8.com/dusk/64/000000/garbage-truck.png';
    } else {
      url = 'https://img.icons8.com/plasticine/100/000000/housekeeping.png';
    }

    if(route){
      this.removeSavedPath();
      this.pathSavedlist = [];
      this.firestore.collection('DrawingRoutes').doc(route).get().forEach(route=>{
        const paths = [];
          route.data().coordinates.forEach(coor => {
            paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
          });
          const path = new google.maps.Polyline({
            path: paths,
            strokeColor: 'red',
            strokeOpacity: 1.0,
            strokeWeight: 1,
          });
          path.setMap(this.map);
          this.pathSavedlist.push(path);
        });

    }

    this.firestore.collection('route', ref => ref.where('id', '==', device.id)
      .where('trip', '==', device.trip).where('deviceId', '==', device.deviceId.toString()))
      .get().forEach(route => {
        if (route.size > 0) {
          route.forEach(doc => {
            const locationLength = doc.data().coordinate.length;
            const mark = {
              position: new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
                doc.data().coordinate[locationLength - 1].longitude),
              map: this.map,
              icon: url,
              trip: device.trip,
              date: doc.data().date_complete.toDate(),
              id: device.deviceId
            };
            const marker = new google.maps.Marker({
              ...mark
            });
            this.lorrylist.push(marker);
            marker.setMap(this.map);
            this.markerlist.push(marker);
            const infoWindow = new google.maps.InfoWindow({
              content: '<span>Device ID: ' + mark.id + '</span><br>' +
                '<span>Trip: ' + mark.trip + '</span><br>'
            });
            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
            this.map.panTo(new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
              doc.data().coordinate[locationLength - 1].longitude));

            if (locationLength > 1) {
              const list = [];
              if (this.trackingPath) {
                this.trackingPath.setMap(null);
              }
              for (let i = 0; i < locationLength; i++) {
                list.push(new google.maps.LatLng(doc.data().coordinate[i].latitude,
                  doc.data().coordinate[i].longitude));
              }
              this.trackingPath = new google.maps.Polyline({
                path: list,
                strokeColor: 'Green',
                strokeOpacity: 1.0,
                strokeWeight: 3,
              });
              this.trackingPath.setMap(this.map);
              this.linelist.push(this.trackingPath);
            }
          });
          if (!this.minimizeGraphFlag && this.role === 'PPS') {
            this.firestore.collection('devices').doc(device.deviceId).collection('trips')
              .doc(device.id).collection('locations').get().forEach((g) => {
                let count = 1;
                g.forEach(graphlabel => {
                  count++;
                  const time = graphlabel.data().last_sync.toDate();
                  this.speedGraph.data.labels.push(time);
                  if (graphlabel.data().speed) {
                    this.speedGraph.data.datasets[0].data.push((graphlabel.data().speed).toFixed(2));
                  } else {
                    this.speedGraph.data.datasets[0].data.push(0);
                  }
                  this.speedGraph.update();
                  if (count === g.size) {
                    this.spinner.hide();
                  }
                });
              });
          }

        } else {
          this.firestore.collection('devices').doc(device.deviceId).collection('trips')
            .doc(device.id).collection('locations').snapshotChanges().subscribe((l) => {
              l.forEach((location, index) => {
                const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
                if (!same) {
                  if (location.payload.doc.data().speed) {
                    this.speed = (location.payload.doc.data().speed ).toFixed(2) + 'km/h';
                  } else {
                    this.speed = '';
                  }
                  this.locatelist.push(location);
                  const date = location.payload.doc.data().last_sync.toDate();
                  if (!this.minimizeGraphFlag && this.role === 'PPS') {
                    this.speedGraph.data.labels.push(date);
                    if (location.payload.doc.data().speed) {
                      this.speedGraph.data.datasets[0].data.push((location.payload.doc.data().speed ).toFixed(2));
                    } else {
                      this.speedGraph.data.datasets[0].data.push(0);
                    }
                    this.speedGraph.update();
                  }

                  if (index === 0) {
                    const mark = {
                      position: new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude),
                      map: this.map,
                      icon: url,
                      trip: device.trip,
                      date: location.payload.doc.data().last_sync.toDate(),
                      id: device.deviceId
                    };
                    const marker = new google.maps.Marker({
                      ...mark
                    });
                    this.lorrylist.push(marker);
                    marker.setMap(this.map);
                    this.markerlist.push(marker);

                    const infoWindow = new google.maps.InfoWindow({
                      content: '<span>Device ID: ' + mark.id + '</span><br>' +
                        '<span>Trip: ' + mark.trip + '</span><br>'
                    });
                    marker.addListener('click', () => {
                      this.map.setZoom(18);
                      this.map.setCenter(marker.getPosition());
                      infoWindow.open(marker.getMap(), marker);
                    });
                    this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude));
                    this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude));
                  } else {
                    const r = this.lorrylist.find(m => m.id === device.deviceId && m.trip === device.trip);

                    if (r) {
                      r.date = location.payload.doc.data().last_sync.toDate();
                      r.setPosition(
                        new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude)
                      );
                      this.map.panTo(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude));
                      const SameThreshold = 0.1;
                      if (google.maps.geometry.spherical.computeDistanceBetween(this.positionlist[this.positionlist.length - 1],
                        new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude)) > SameThreshold) {
                        this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude));
                        this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().location.latitude,
                          location.payload.doc.data().location.longitude));
                      }
                      if (index === l.length - 1) {
                        if (device.date_complete) {
                          const data = {
                            coordinate: this.savinglist,
                            ...device
                          };
                          this.firestore.collection('route').add(data);
                        }
                        if (this.positionlist.length > 1) {
                          const list = [];
                          if (this.trackingPath) {
                            this.trackingPath.setMap(null);
                          }
                          for (let i = 0; i < this.positionlist.length; i++) {
                            list.push(this.positionlist[i]);
                          }
                          this.trackingPath = new google.maps.Polyline({
                            path: list,
                            strokeColor: 'Green',
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                          });
                          this.trackingPath.setMap(this.map);
                          this.linelist.push(this.trackingPath);
                          this.spinner.hide();
                        } else {
                          this.spinner.hide();
                        }
                      }
                    }
                  }
                  index++;
                }
              });
            });
        }
      });


  }

  GetImage(deviceID, date, trip) {
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    console.log(deviceID);
    console.log(dateString);
    console.log(trip);

    this.firestore.collection('ppsReports').doc(dateString).
      collection('reports', ref => ref.where('deviceId', '==', deviceID).
        where('trip', '==', trip.toString()).where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
          console.log(result);
          result.forEach(e => {
            const info = {
              position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                e.payload.doc.data().location.longitude),
              latitude: e.payload.doc.data().location.latitude,
              longitude: e.payload.doc.data().location.longitude,
              map: this.map,
              id: e.payload.doc.data().deviceId,
              date: e.payload.doc.data().date,
              imageurl: e.payload.doc.data().image,
              imagetype: e.payload.doc.data().type,
            };
            this.imagelist.push(info);
          });
          this.imagelist.forEach(e => {
            let marker;
            if (e.type === 'operasi') {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'

              });
            } else {
              marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'

              });
            }
            marker.setMap(this.map);
            this.map.setCenter(marker.getPosition());
            let infoWindow;
            const d = new Date(e.date.toDate());
            const hrs = d.getHours();
            const minute = d.getMinutes();
            const sec = d.getSeconds();
            let display;
            if (hrs > 12) {
              display = hrs + ':' + minute + ':' + sec + 'pm';
            } else {
              display = hrs + ':' + minute + ':' + sec + 'am';
            }
            if (e.type === 'operasi') {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Operasi:' + display + ' </span><br><br>' +
                  '<div class="operasiFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            } else {
              infoWindow = new google.maps.InfoWindow({
                content:
                  '<span>Waktu Aduan Halangan:' + display + ' </span><br><br>' +
                  '<div class="aduanFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
              });
            }

            marker.addListener('click', () => {
              this.map.setZoom(18);
              this.map.setCenter(marker.getPosition());
              infoWindow.open(marker.getMap(), marker);
            });
          });
        });
  }
  streets = [];
  savedMarkerlist = [];

  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }

  removeMarkerlist() {
    this.savedMarkerlist.forEach(e => {
      e.setMap(null);
    })
  }
  service:any;

  aduanlist = [];
  async GetKPAImage(date) {
    this.spinner.show();
    if (this.pathSavedlist.length > 0) {
      this.removeSavedPath();
    }
    if (this.savedMarkerlist.length > 0) {
      this.removeMarkerlist();
    }
    this.pathSavedlist = [];
    this.streets = [];
    this.savedMarkerlist = [];
    this.imagelist = [];
    this.aduanlist = [];
    const check = date.getDay();
    let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
    if(this.email === 'jppausm@vljejak.com')
      filter = ['USM MINDEN'];


    await this.firestore.collection('streets', ref => ref.where('belongZone','in',filter).orderBy('streetName', 'asc')).get().forEach(async streets => {
      let counter = 1;
      if (streets.size === 0) {
        this.spinner.hide();
      }
      await streets.forEach(streetdoc => {
        if (this.filterzone) {
          if (streetdoc.data().belongZone) {
            if (this.filterzone === streetdoc.data().belongZone) {
              if (this.getCheck(streetdoc.data(), check)) {
                this.streets.push(streetdoc.data().streetName);
                const paths = [];
                streetdoc.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                const path = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'red',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                path.setMap(this.map);

                this.pathSavedlist.push(path);
                this.map.panTo(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
                streetdoc.data().centerCoordinate.longitude));

              }
            }
          }
        } else {
          if (this.getCheck(streetdoc.data(), check)) {
            this.streets.push(streetdoc.data().streetName);
            const paths = [];
            streetdoc.data().coordinates.forEach(coor => {
              paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
            });

            const path = new google.maps.Polygon({
              paths,
              strokeColor: 'black',
              fillColor: 'red',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            path.setMap(this.map);
            this.pathSavedlist.push(path);
            this.map.panTo(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude, streetdoc.data().centerCoordinate.longitude));
          }
        }


        if (counter === streets.size) {
        }
        counter++;

      });
    });


    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.service = this.firestore.collection('reports').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
        this.removeMarkerlist();
        result.forEach(e => {
            let info = {};
            const r = this.imagelist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
              i.choosedStreet === e.payload.doc.data().choosedStreet);
            if (r) {
              if (e.payload.doc.data().type === 'sebelum') {
                r.imagesebelum = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'semasa') {
                r.imagesemasa = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'selepas') {
                r.imageselepas = e.payload.doc.data().image;
              }
            } else {
              if (e.payload.doc.data().type === 'sebelum') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: e.payload.doc.data().image,
                  imageselepas: '../../../assets/notyet.png',
                  imagesemasa: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'semasa') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imagesemasa: e.payload.doc.data().image,
                  imageselepas: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'selepas') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imagesemasa: '../../../assets/notyet.png',
                  imageselepas: e.payload.doc.data().image,
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              }
            }

        });

        this.imagelist = this.imagelist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        this.spinner.hide();

        this.imagelist.forEach(async e => {

          await this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
            s.forEach(document => {
              var ind = this.streets.findIndex(a => a === document.data().streetName);
              if (ind > -1) {

                e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                  document.data().centerCoordinate.longitude);
                const marker = new google.maps.Marker({
                  ...e,
                  icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                });
                marker.setMap(this.map);
                this.savedMarkerlist.push(marker);
                this.map.setCenter(marker.getPosition());
                const findsameStreet = this.imagelist.filter(i => (i.choosedStreet === e.choosedStreet) && (i.choosedWork !== e.choosedWork) && !i.flag);
                let infoWindow;
                let d = new Date();
                try {
                  d = new Date(e.date.toDate());

                } catch (error) {
                  d = new Date(e.date);

                }
                let htmlfull = '';
                let hrs = d.getHours();
                let minute = d.getMinutes();
                let sec = d.getSeconds();
                let display;
                if (hrs > 12) {
                  display = hrs + ':' + minute + ':' + sec + 'pm';
                } else {
                  display = hrs + ':' + minute + ':' + sec + 'am';
                }
                let html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                  '<span>Nama Jalan:' + e.choosedStreet + ' </span><br>' +
                  '<span>Aktiviti:' + e.choosedWork + ' </span><br>' +
                  '<div class="row">' +
                  '<div class="col" >Sebelum:</div>' +
                  '<div class="col" >Semasa:</div>' +
                  '<div class="col" >Selepas:</div>' +
                  '</div>' +
                  '<div class="row">' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesebelum + ' width="100%" height="100%"></img></div>' +
                  '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesemasa + ' width="100%" height="100%"></img></div>' +
                  '<div class="col"  style="width: 150px;height: 150px;"><img src=' + e.imageselepas + ' width="100%" height="100%"></img></div>' +
                  '</div>';
                htmlfull += html;
                if (findsameStreet.length > 0) {

                  findsameStreet.forEach(element => {
                    element.flag = true;
                    try {
                      d = new Date(element.date.toDate());

                    } catch (error) {
                      d = new Date(element.date);

                    }
                    hrs = d.getHours();
                    minute = d.getMinutes();
                    sec = d.getSeconds();
                    if (hrs > 12) {
                      display = hrs + ':' + minute + ':' + sec + 'pm';
                    } else {
                      display = hrs + ':' + minute + ':' + sec + 'am';
                    }
                    html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                      '<span>Nama Jalan:' + element.choosedStreet + ' </span><br>' +
                      '<span>Aktiviti:' + element.choosedWork + ' </span><br>' +
                      '<div class="row">' +
                      '<div class="col" >Sebelum:</div>' +
                      '<div class="col" >Semasa:</div>' +
                      '<div class="col" >Selepas:</div>' +
                      '</div>' +
                      '<div class="row">' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesebelum + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesemasa + ' width="100%" height="100%"></img></div>' +
                      '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imageselepas + ' width="100%" height="100%"></img></div>' +
                      '</div>';
                    htmlfull += html;

                  });
                }
                infoWindow = new google.maps.InfoWindow({
                  content: htmlfull
                });


                marker.addListener('click', () => {
                  this.map.setZoom(20);
                  this.map.setCenter(marker.getPosition());
                  infoWindow.open(marker.getMap(), marker);
                });


                const paths = [];
                document.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                this.pathSavedlist[ind].setMap(null);
                this.pathSavedlist[ind] = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                this.pathSavedlist[ind].setMap(this.map);


              }

            });
          });

        });
      });

    this.firestore.collection('complaints').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', 'aduan')).snapshotChanges().subscribe(result => {
        result.forEach(e => {
          let info = {};
          const r = this.aduanlist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
            i.choosedStreet === e.payload.doc.data().choosedStreet);
          if (r) {
            if (e.payload.doc.data().type === 'sebelum') {
              r.imagesebelum = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'semasa') {
              r.imagesemasa = e.payload.doc.data().image;
            } else if (e.payload.doc.data().type === 'selepas') {
              r.imageselepas = e.payload.doc.data().image;
            }
          } else {
            if (e.payload.doc.data().type === 'sebelum') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                  e.payload.doc.data().location.longitude),
                latitude: e.payload.doc.data().location.latitude,
                longitude: e.payload.doc.data().location.longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,
              };
              this.aduanlist.push(info);

            } else if (e.payload.doc.data().type === 'semasa') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                  e.payload.doc.data().location.longitude),
                latitude: e.payload.doc.data().location.latitude,
                longitude: e.payload.doc.data().location.longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: e.payload.doc.data().image,
                imageselepas: '../../../assets/notyet.png',
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,

              };
              this.aduanlist.push(info);

            } else if (e.payload.doc.data().type === 'selepas') {
              info = {
                position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
                  e.payload.doc.data().location.longitude),
                latitude: e.payload.doc.data().location.latitude,
                longitude: e.payload.doc.data().location.longitude,
                map: this.map,
                id: e.payload.doc.data().deviceId,
                date: e.payload.doc.data().date,
                imagesebelum: '../../../assets/notyet.png',
                imagesemasa: '../../../assets/notyet.png',
                imageselepas: e.payload.doc.data().image,
                choosedStreet: e.payload.doc.data().choosedStreet,
                choosedWork: e.payload.doc.data().choosedWork,
                flag: false,
              };
              this.aduanlist.push(info);

            }
          }
        });

        this.aduanlist = this.aduanlist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        this.spinner.hide();

        this.aduanlist.forEach(async e => {

          await this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
            s.forEach(document => {
              var ind = this.streets.findIndex(a => a === document.data().streetName);
              if (ind > -1) {

              e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                document.data().centerCoordinate.longitude);
              const marker = new google.maps.Marker({
                ...e,
                icon: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
              });
              marker.setMap(this.map);
              this.savedMarkerlist.push(marker);
              this.map.setCenter(marker.getPosition());
              const findsameStreet = this.aduanlist.filter(i => (i.choosedStreet === e.choosedStreet) && (i.choosedWork !== e.choosedWork) && !i.flag);
              let infoWindow;
              let d = new Date();
              try {
                d = new Date(e.date.toDate());

              } catch (error) {
                d = new Date(e.date);

              }
              let htmlfull = '';
              let hrs = d.getHours();
              let minute = d.getMinutes();
              let sec = d.getSeconds();
              let display;
              if (hrs > 12) {
                display = hrs + ':' + minute + ':' + sec;
              } else {
                display = hrs + ':' + minute + ':' + sec;
              }
              let html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                '<span>Nama Jalan:' + e.choosedStreet + ' </span><br>' +
                '<span>Aktiviti:' + e.choosedWork + ' </span><br>' +
                '<div class="row">' +
                '<div class="col" >Sebelum:</div>' +
                '<div class="col" >Semasa:</div>' +
                '<div class="col" >Selepas:</div>' +
                '</div>' +
                '<div class="row">' +
                '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesebelum + ' width="100%" height="100%"></img></div>' +
                '<div class="col" style="width: 150px;height: 150px;"><img src=' + e.imagesemasa + ' width="100%" height="100%"></img></div>' +
                '<div class="col"  style="width: 150px;height: 150px;"><img src=' + e.imageselepas + ' width="100%" height="100%"></img></div>' +
                '</div>';
              htmlfull += html;
              if (findsameStreet.length > 0) {

                findsameStreet.forEach(element => {
                  element.flag = true;
                  try {
                    d = new Date(element.date.toDate());

                  } catch (error) {
                    d = new Date(element.date);

                  }
                  hrs = d.getHours();
                  minute = d.getMinutes();
                  sec = d.getSeconds();
                  if (hrs > 12) {
                    display = hrs + ':' + minute + ':' + sec;
                  } else {
                    display = hrs + ':' + minute + ':' + sec;
                  }
                  html = '<br><span>Waktu Operasi:' + display + ' </span><br>' +
                    '<span>Nama Jalan:' + element.choosedStreet + ' </span><br>' +
                    '<span>Aktiviti:' + element.choosedWork + ' </span><br>' +
                    '<div class="row">' +
                    '<div class="col" >Sebelum:</div>' +
                    '<div class="col" >Semasa:</div>' +
                    '<div class="col" >Selepas:</div>' +
                    '</div>' +
                    '<div class="row">' +
                    '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesebelum + ' width="100%" height="100%"></img></div>' +
                    '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imagesemasa + ' width="100%" height="100%"></img></div>' +
                    '<div class="col"  style="width: 150px;height: 150px;"><img src=' + element.imageselepas + ' width="100%" height="100%"></img></div>' +
                    '</div>';
                  htmlfull += html;

                });
              }
              infoWindow = new google.maps.InfoWindow({
                content: htmlfull
              });


              marker.addListener('click', () => {
                this.map.setZoom(20);
                this.map.setCenter(marker.getPosition());
                infoWindow.open(marker.getMap(), marker);
              });

              const paths = [];
              document.data().coordinates.forEach(coor => {
                paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
              });
              this.pathSavedlist[ind].setMap(null);
              this.pathSavedlist[ind] = new google.maps.Polygon({
                paths,
                strokeColor: 'black',
                fillColor: 'yellow',
                strokeOpacity: 1.0,
                strokeWeight: 2,
              });
              this.pathSavedlist[ind].setMap(this.map);




             }
             });
          });

        });
      });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  viewDetail(id) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = '50%';
    dialogConfig.width = '50%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      id,
    };

    this.dialog.open(DeviceInfoModalComponent, dialogConfig);
  }

  minimize() {
    this.minimizeFlag = true;
  }
  minimizeGraph() {
    this.minimizeGraphFlag = true;
  }

  maximize() {
    this.minimizeFlag = false;
  }

  maximizeGraph() {
    this.minimizeGraphFlag = false;

    setTimeout(() => {
      if (this.speedGraph) {
        this.speedGraph.destroy();
      }
      this.speedGraph = new Chart('graph', {
        type: 'line',
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              borderColor: 'Blue',
              label: 'Speed (km/h)',
              fill: false
            },
          ]
        },
        options: {
          plugins: {
            zoom: {
              pan: {
                enabled: true,
                mode: 'x'
              },
              zoom: {
                enabled: true,
                mode: 'x'
              }
            }
          },
          legend: {
            display: true
          },

          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Period'
              },
              type: 'time',
              time: {
                unit: 'hour'
              },

            }],
            yAxes: [{
              display: true
            }],
          },
          responsive: false,
          maintainAspectRatio: false
        },
      });
    }, 100);

  }
  kpaDate = new Date();
  selectDate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(MonitorDatePickerComponent, dialogConfig).afterClosed().subscribe((date: any) => {
      if (date) {
        this.kpaDate = date;
        this.GetKPAImage(this.kpaDate);
      }
    });

  }

  addMonitorMap(deviceId) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(MonitorDatePickerComponent, dialogConfig).afterClosed().subscribe((date: any) => {
      if (date) {
        const d = new Date(date);
        this.deletecounter++;
        const trackingdevice = {
          id: deviceId,
          deleteID: deviceId + this.deletecounter,
          date: d,
        };
        const result = this.maplist.find(map => map.id === trackingdevice.id &&
          map.date.getFullYear() === trackingdevice.date.getFullYear() &&
          map.date.getMonth() === trackingdevice.date.getMonth() &&
          map.date.getDate() === trackingdevice.date.getDate());
        if (!result) {
          if (this.mode === 'single') {
            this.maplist = [];
            this.maplist.push(trackingdevice);
            this.devices = deviceId;
            this.removeMarkers(this.markerlist);
            this.removePath(this.linelist);
            this.markerlist = [];
            this.linelist = [];
            this.lorrylist = [];
            this.positionlist = [];
            this.savinglist = [];
            this.imagelist = [];
            if (!this.minimizeGraphFlag && this.role === 'PPS') {
              this.speedGraph.data.datasets[0].data = [];
              this.speedGraph.data.labels = [];
              this.speedGraph.update();
            }
            if (this.role === 'PPS') {
              this.MonitorInMap(deviceId, date);
            }
          } else {
            this.maplist.push(trackingdevice);
            if (this.maplist.length === 1) {
              this.devices = deviceId;
              this.removeMarkers(this.markerlist);
              this.removePath(this.linelist);
              this.markerlist = [];
              this.linelist = [];
              this.lorrylist = [];
              this.positionlist = [];
              this.savinglist = [];
              this.imagelist = [];
              if (!this.minimizeGraphFlag && this.role === 'PPS') {
                this.speedGraph.data.datasets[0].data = [];
                this.speedGraph.data.labels = [];
                this.speedGraph.update();
              }
              if (this.role === 'PPS') {
                this.MonitorInMap(deviceId, date);
              }
            }

          }
        } else {
          this.toaster.error('This device and the chosen date already been tracking', 'Error');
        }
      }
    });
  }

  async detectChanges(event) {
    this.maplist = this.fitlerDevice(this.maplist, event);
    setTimeout(async () => {
      if (this.maplist.length === 1) {
        this.trips = [];
        await this.ngAfterViewInit();
        this.devices = this.maplist[0].id;
        this.selectedIndex = this.selectedIndex - 1;
        this.removeMarkers(this.markerlist);
        this.removePath(this.linelist);
        this.markerlist = [];
        this.linelist = [];
        this.lorrylist = [];
        this.positionlist = [];
        this.savinglist = [];

        this.imagelist = [];
        if (!this.minimizeGraphFlag && this.role === 'PPS') {
          this.speedGraph.data.datasets[0].data = [];
          this.speedGraph.data.labels = [];
          this.speedGraph.update();
        }

        this.MonitorInMap(this.maplist[0].id, this.maplist[0].date);
      }
    }, 1000);


  }

  fitlerDevice = (arr, query) => {
    return arr.filter(el => (el.id !== query.id || el.deleteID !== query.deleteID));

  }

  stopMonitor() {
    this.removeMarkers(this.markerlist);
    this.removePath(this.linelist);
    this.devices = '';
    this.speed = '';
    this.markerlist = [];
    this.linelist = [];
    this.lorrylist = [];
    this.positionlist = [];
    this.savinglist = [];
    this.imagelist = [];
    this.trips = [];
    if (!this.minimizeGraphFlag && this.role === 'PPS') {
      this.speedGraph.data.datasets[0].data = [];
      this.speedGraph.data.labels = [];
      this.speedGraph.update();
    }
    this.maplist = [];
  }
  single() {
    this.mode = 'single';
  }

  multiple() {
    this.mode = 'multiple';
  }
  applyFilter(filterValue: string) {
    this.sorteddevicelist = this.devicelist.slice(0);
    this.sorteddevicelist = this.filterItems(this.sorteddevicelist, filterValue);
  }

  filterItems = (arr, query) => {
    return arr.filter(el => el.id.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  applyFilter2(filterValue: string) {
    this.sortedzonelist = this.zonelist.slice(0);
    this.sortedzonelist = this.filterItems2(this.sortedzonelist, filterValue);
  }

  filterItems2 = (arr, query) => {
    return arr.filter(el => el.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }
  getCheck(street, day) {
    if(day === 0){
      day = 7;
    }
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }

  filterzone = '';
  showZone(zone) {
    this.filterzone = zone.name;
    this.GetKPAImage(this.kpaDate);
  }
  clear(){
    this.filterzone = '';
    this.GetKPAImage(this.kpaDate);

  }

  tripSelect(t){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      trips: this.trips.slice(),
      devices: this.devices,
      date:this.maplist[0].date
    };

    const deviceRoute = [];
    this.firestore.collection('DrawingRoutes',ref=>ref.where('belongDevice','==',this.devices)).get().forEach(routes=>{
      if(routes.size === 0){
        this.StartTracking(t,null);
        this.GetImage(this.devices, this.maplist[0].date, t.trip);
      }
      routes.forEach(r=>{
        const info={
          ...r.data()
        }
        deviceRoute.push(info);
      })
      dialogConfig.data = {
        routes: deviceRoute.slice(),
        devices: this.devices,
        date:this.maplist[0].date
      };

      this.dialog.open(RouteSelectComponent, dialogConfig).afterClosed().subscribe(route => {
        if(route){
          this.StartTracking(t,route);
          this.GetImage(this.devices, this.maplist[0].date, t.trip);

        }
      });
    })
  }
}
