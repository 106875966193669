import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.css']
})
export class CreateDeviceComponent implements OnInit {

  device = {
    id: "",
    role: "",
    zoneAssigned:null,
    zoneAssigneds: []
  };
  zone1: any;
  zone2: any;
  zone3: any;
  zonelist = [];

  updateForm= false;
  constructor(
    private dialogRef: MatDialogRef<CreateDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService
  ) {
    this.zonelist = data;
  }

  ngOnInit(): void {

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {

    if (!this.device.id || !this.device.role) {
      this.toast.warning('Information not completed', 'Please check')
      return;
    }
    if (this.device.role === 'Inspector') {
      if(this.zone2 && this.zone3 && this.zone1){
        if (this.zone1 === this.zone2 || this.zone1 === this.zone3 || this.zone2 === this.zone3) {
          this.toast.warning('Same zone has been choosed', 'Please check')
          return;
        }
      }

    }


    let flag = false;
    this.firestore.collection('devices').doc(this.device.id).get().forEach(r => {
      if (r.exists) {
        flag = true;
      }
        // this.toast.warning('Same device ID detected', 'Please check')
        // return;
      // } else {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.height = 'auto';
        dialogConfig.width = '400px';
        const position = {
          top: '5%',
        };
        dialogConfig.position = position;
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {
          message: 'Do you want to create this device ID? ' + this.device.id,
        }

        this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
          if (result) {
            this.spinner.show();
            if (this.zone1) {
              const zoneinfo = this.zonelist.find(z => z.name === this.zone1).name
              this.device.zoneAssigneds.push(zoneinfo);
              this.device.zoneAssigned = zoneinfo;
            }
            if (this.zone2 && this.device.role === 'Inspector') {
              const zoneinfo = this.zonelist.find(z => z.name === this.zone2).name

              this.device.zoneAssigneds.push(zoneinfo);
            }
            if (this.zone3 && this.device.role === 'Inspector') {
              const zoneinfo = this.zonelist.find(z => z.name === this.zone3).name

              this.device.zoneAssigneds.push(zoneinfo);

            }
            if(this.device.role === 'Inspector'){
              this.device.zoneAssigned = null;
            }else{
              this.device.zoneAssigneds = [];
            }
            if(flag){
              this.firestore.collection('devices').doc(this.device.id).update(this.device).then(r => {
                this.spinner.hide();

                this.dialogRef.close(true);
              });
            }else{
              this.firestore.collection('devices').doc(this.device.id).set(this.device).then(r => {
                this.spinner.hide();

                this.dialogRef.close(true);
              });
            }

          }
        });

    })

  }


  // }


}
