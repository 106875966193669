import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

@Component({
  selector: 'app-qr-pop-up',
  templateUrl: './qr-pop-up.component.html',
  styleUrls: ['./qr-pop-up.component.css']
})
export class QrPopUpComponent implements OnInit {
  qr = "https://smart-waste-system.web.app/#/KpaWeb/";
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;
  constructor(private dialogRef: MatDialogRef<QrPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.qr += data.id;
    this.title = data.title
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }
  downloadQRCode() {
   const fileNameToDownload = this.title.replace(/\s/g,'-') + "QRCode";
   const base64Img = document.getElementsByClassName('coolQRCode')[0].children[0]['src'];
   fetch(base64Img)
      .then(res => res.blob())
      .then((blob) => {
         // IE
         if (window.navigator && window.navigator.msSaveOrOpenBlob){
            window.navigator.msSaveOrOpenBlob(blob,fileNameToDownload);
         } else { // Chrome
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = fileNameToDownload;
            link.click();
         }
      })
}

}
