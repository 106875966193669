import { Chart } from 'chart.js';
import { MediaMatcher } from '@angular/cdk/layout';
import { Input } from '@angular/core';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-zone-table',
  templateUrl: './zone-table.component.html',
  styleUrls: ['./zone-table.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class ZoneTableComponent implements OnInit {
  @Input() zone: any;


  minDate: Date;
  maxDate: Date;
  dateDisplay = new FormControl(moment());
  date: Date;


  months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  shortForm = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]


  monthLine: Chart
  barChart: Chart

  showingMonth = [];
  showingMonthStr = [];
  constructor( changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService) {
    const currentYear = new Date().getFullYear();
      this.minDate = new Date(currentYear - 20, 0, 1);
      this.maxDate = new Date();
      this.date = new Date();
    }

    chosenYearHandler(normalizedYear: Moment) {
      const ctrlValue = this.dateDisplay.value;
      ctrlValue.year(normalizedYear.year());
      this.dateDisplay.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
      const ctrlValue = this.dateDisplay.value;
      ctrlValue.month(normalizedMonth.month());
      this.dateDisplay.setValue(ctrlValue);
      this.date = new Date(this.dateDisplay.value);
      datepicker.close();

      this.initializeGraph();
      this.getZoneInfo(this.zone);
    }

  ngOnInit() {
    this.getZoneInfo(this.zone);
  }

  showingDate = [];

  ngAfterViewInit(): void {
   this.initializeGraph();

  }

  initializeGraph(){
    this.showingMonth=[];
    this.showingDate=[];
    this.showingMonthStr = [];

    if(this.monthLine){
      this.monthLine.destroy()
    }

    if(this.barChart){
      this.barChart.destroy()
    }
    const currentMonth = this.date.getMonth();
    this.fakeArray = new Array(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate());

    for (let index = 0; index < this.fakeArray.length; index++) {
      const days = new Date( this.date.getFullYear(), this.date.getMonth(), index+1);
      const day = ('0' + days.getDate()).slice(-2);
      const dateString = this.shortForm[days.getMonth()] + ' ' +day;
      if(days.getDate() > new Date().getDate()
      && days.getMonth() === new Date().getMonth()
      && days.getFullYear() === new Date().getFullYear()){
        continue;
      }
      this.showingDate.push(dateString)

    }

    if(currentMonth >3 ){
      this.showingMonth.push(this.months[currentMonth -4]);
      this.showingMonth.push(this.months[currentMonth -3]);
      this.showingMonth.push(this.months[currentMonth -2]);
      this.showingMonth.push(this.months[currentMonth -1]);
      this.showingMonth.push(this.months[currentMonth] );

      this.showingMonthStr.push(('0' + (currentMonth-4 + 1)).slice(-2)+"/01/"+new Date().getFullYear());
      this.showingMonthStr.push(('0' + (currentMonth-3 + 1)).slice(-2)+"/01/"+new Date().getFullYear());
      this.showingMonthStr.push(('0' + (currentMonth-2 + 1)).slice(-2)+"/01/"+new Date().getFullYear());
      this.showingMonthStr.push(('0' + (currentMonth-1 + 1)).slice(-2)+"/01/"+new Date().getFullYear());
      this.showingMonthStr.push(('0' + (currentMonth + 1)).slice(-2)+"/01/"+new Date().getFullYear());

    }else{
      switch(currentMonth){
        case 0:
          this.showingMonth.push(this.months[8]);
          this.showingMonth.push(this.months[9]);
          this.showingMonth.push(this.months[10]);
          this.showingMonth.push(this.months[11]);
          this.showingMonth.push(this.months[0]);
          this.showingMonthStr.push("09/01/"+ (new Date().getFullYear()-1));
          this.showingMonthStr.push("10/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("11/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("12/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("01/01/"+new Date().getFullYear());

          break;
        case 1:
          this.showingMonth.push(this.months[9]);
          this.showingMonth.push(this.months[10]);
          this.showingMonth.push(this.months[11]);
          this.showingMonth.push(this.months[0]);
          this.showingMonth.push(this.months[1]);
          this.showingMonthStr.push("10/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("11/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("12/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("01/01/"+new Date().getFullYear());
          this.showingMonthStr.push("02/01/"+ (new Date().getFullYear()));

          break;
        case 2:
          this.showingMonth.push(this.months[10]);
          this.showingMonth.push(this.months[11]);
          this.showingMonth.push(this.months[0]);
          this.showingMonth.push(this.months[1]);
          this.showingMonth.push(this.months[2]);
          this.showingMonthStr.push("11/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("12/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("01/01/"+new Date().getFullYear());
          this.showingMonthStr.push("02/01/"+ (new Date().getFullYear()));
          this.showingMonthStr.push("03/01/"+(new Date().getFullYear()));

          break;
        case 3:
          this.showingMonth.push(this.months[11]);
          this.showingMonth.push(this.months[0]);
          this.showingMonth.push(this.months[1]);
          this.showingMonth.push(this.months[2]);
          this.showingMonth.push(this.months[3]);
          this.showingMonthStr.push("12/01/"+(new Date().getFullYear()-1));
          this.showingMonthStr.push("01/01/"+new Date().getFullYear());
          this.showingMonthStr.push("02/01/"+ (new Date().getFullYear()));
          this.showingMonthStr.push("03/01/"+(new Date().getFullYear()));
          this.showingMonthStr.push("04/01/"+(new Date().getFullYear()));

          break;
      }
    }

    this.barChart = new Chart('barChart' + this.zone.replaceAll(' ',''), {
      type: 'bar',
      data: {
        labels: this.showingDate,
        datasets: [{
          label: this.zone,
          data: [],
          backgroundColor:"#01b2f7",
          borderColor:"#01b2f7"
        },
        {
          label: "Remaing percentage",
          data: [],
        }],

      },
      options: {
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
                return "";
            },
            color: '#fff',
          }
        },
      maintainAspectRatio: false,
        tooltips: {
          callbacks: {
              label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                      label += ': ';
                  }
                  label += Math.round(tooltipItem.yLabel * 100) / 100 + "% ";
                  return label;
              }
          }
      },
      legend:{
        display:false,
      },
        scales: {

          yAxes: [{
            stacked:true,
            display:false,
            ticks: {
              beginAtZero: true,
              max: 100
            },
            gridLines: {
              display: false,
            },
          }],
          xAxes: [{
            stacked:true,
            gridLines: {
              display: false,
            },
          }]
        },
      }

    });

    this.monthLine = new Chart('monthLine' + this.zone.replaceAll(' ',''), {
      type: 'line',
      data: {
        labels: this.showingMonth,
        datasets: [{
          label: this.zone ,
          data: [],
          fill:false,
          backgroundColor:"#01b2f7",
          borderColor:"#01b2f7"
        }],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
                return "";
            },
            color: '#fff',
          }
        },
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
              label: function(tooltipItem, data) {
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';

                  if (label) {
                      label += ': ';
                  }
                  label += Math.round(tooltipItem.yLabel * 100) / 100 + "% ";
                  return label;
              }
          }
      },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 100
            }
          }]
        },
      }

    });
    this.getLineGraph()
  }



  getCurrentMonth() {
    if(!this.date)
      this.date = new Date();
    return  this.months[this.date.getMonth()];
  };

  fakeArray = [];

  async getZoneInfo(zone){

    this.monday = 0;
    this.tuesday =0;
    this.wednesday = 0;
    this.thursday = 0;
    this.friday =0 ;
    this.saturday=0;
    this.sunday=0;

    if(!this.date)
      this.date = new Date();
    this.spinner.show();
    await this.firestore.collection('streets',ref => ref.where('belongZone', '==', zone)).get().forEach(async streets => {
      await streets.forEach(streetdoc => {
        this.getCheck(streetdoc.data());
      });
    });
    const d =  new Date(this.date);
    this.fakeArray = new Array(new Date(d.getFullYear(), d.getMonth()+1, 0).getDate());
    this.count = new Array(new Date(d.getFullYear(), d.getMonth()+1, 0).getDate());;

    for (let index = 0; index < this.fakeArray.length; index++) {
      const days = new Date( d.getFullYear(), d.getMonth(), index+1);
      if(days.getDate() > new Date().getDate()
      && days.getMonth() === new Date().getMonth()
      && days.getFullYear() === new Date().getFullYear()){
        continue;
      }
      this.check(days,index);
    }

  }

  monday = 0;
  tuesday =0;
  wednesday = 0;
  thursday = 0;
  friday =0 ;
  saturday=0;
  sunday=0;


  getDay(index){
    const d =  new Date(this.date);
    const days = new Date( d.getFullYear(), d.getMonth(), index+1);
    const result = days.getDay();
    switch(result){
      case 1: return this.monday;
        case 2: return this.tuesday;
        case 3: return this.wednesday;
        case 4: return this.thursday;
        case 5: return this.friday;
        case 6: return this.saturday;
        case 0: return this.sunday;
    }
  }

  getCheck(street) {
    street.dayAvailables.forEach(element => {
      switch(element){
        case 1: this.monday++; break;
        case 2: this.tuesday++; break;
        case 3: this.wednesday++; break;
        case 4: this.thursday++; break;
        case 5: this.friday++; break;
        case 6: this.saturday++; break;
        case 7: this.sunday++; break;
      }
    });
  }

  imagelist= [];

  count = [];
  check(date,index){
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dayString = date.getDay();
    const dateString = year + month + day;

    this.firestore.collection('reports').doc(dateString).get().forEach(r=>{
      const re = r.data()['Completed' + this.zone]
      if((re || re === 0 )&& (
        date.getDate() !== new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
        )){
        this.count[index] = r.data()['Completed' + this.zone];
      }else{
        this.firestore.collection('reports').doc(dateString).
        collection('reports', ref => ref.where('mode', '==', 'kpa').where('zone','==',this.zone)).get().forEach(result => {
          var counter = 0;
          this.imagelist = [];
          result.forEach(e => {
              let info = {};
              const r = this.imagelist.find(i => i.choosedWork === e.data().choosedWork &&
                i.choosedStreet === e.data().choosedStreet);
              if (r) {
                if (e.data().type === 'sebelum') {
                  r.imagesebelum = e.data().image;
                } else if (e.data().type === 'semasa') {
                  r.imagesemasa = e.data().image;
                } else if (e.data().type === 'selepas') {
                  r.imageselepas = e.data().image;
                }
              } else {
                if (e.data().type === 'sebelum') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'semasa') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'selepas') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    imageselepas: e.data().image,
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                }
              }

          });

          this.imagelist = this.imagelist.sort((a, b) => {
            return this.compare(a.choosedStreet, b.choosedStreet, true);
          })

          this.imagelist.forEach(async (e,index) => {
            if(e.imagesebelum === '../../../assets/notyet.png' || e.imageselepas === '../../../assets/notyet.png'  || e.imagesemasa === '../../../assets/notyet.png'){
              return;
            }
            if(index > 1){
              if(e.choosedStreet === this.imagelist[index-1].choosedStreet){
                return;
              }
            }
            counter++;
          });
          this.count[index] = counter;
          const completed={}
          completed['Completed'+ this.zone] = counter

          switch(dayString){
            case 1: completed['Total'+ this.zone] = this.monday;break;
            case 2: completed['Total'+ this.zone] = this.tuesday;break;
            case 3: completed['Total'+ this.zone] = this.wednesday;break;
            case 4: completed['Total'+ this.zone] = this.thursday;break;
            case 5: completed['Total'+ this.zone] = this.friday;break;
            case 6: completed['Total'+ this.zone] = this.saturday;break;
            case 0: completed['Total'+ this.zone] = this.sunday;break;
          }


          this.firestore.collection('reports').doc(dateString).update(completed);
        });
      }
    })


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  calculate(completed, schedule){
    const result = ((completed/schedule)*100)
    if(isNaN(result)){
      return 0 + "%"
    }else{
      return result.toFixed(0) + "%"
    }
  }

  async getLineGraph(){
    let c = 0;
    for (const month of this.showingMonthStr) {
      console.log(month);
        c++;
        const d =  new Date(month);
        this.fakeArray = new Array(new Date(d.getFullYear(), d.getMonth()+1, 0).getDate());
        const arrayDate = [];
        let counter = 0;
        for (let index = 0; index < this.fakeArray.length; index++) {
          const days = new Date( d.getFullYear(), d.getMonth(), index+1);
          if(days.getDate() > new Date().getDate()
          && days.getMonth() === new Date().getMonth()
          && days.getFullYear() === new Date().getFullYear()){
            continue;
          }
          const year = days.getFullYear();
          const day = ('0' + days.getDate()).slice(-2);
          const month = ('0' + (days.getMonth() + 1)).slice(-2);
          const dateString = year + month + day;

          arrayDate.push(dateString);
          counter++;
        }

        const result = await this.getReport(arrayDate);

        let totalMonthValue = 0;
        for (const value of result) {

          if(value['Completed'+ this.zone] && value['Total'+ this.zone] ){
            totalMonthValue += value['Completed'+ this.zone] /  value['Total'+ this.zone] * 100;
            if(c === 5){
              let dailyValue = value['Completed'+ this.zone] /  value['Total'+ this.zone] * 100;
              this.barChart.data.datasets[0].data.push(dailyValue);
              this.barChart.data.datasets[1].data.push(100 - dailyValue);

              this.barChart.update();

            }
          }else{
            totalMonthValue += 0
          }
        }
        totalMonthValue = totalMonthValue/ counter;
        this.monthLine.data.datasets[0].data.push(totalMonthValue);
        this.monthLine.update();
    }
    this.spinner.hide();

  }

  getReport(ids): Promise<any> {
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length) return res([]);

      let batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);
        // add the batch request to to a queue

        for (const b of batch) {
          batches.push(
            new Promise(response => {
              this.firestore.collection('reports').doc(b)
                .get().forEach(results => {
                  response(results.data())
                })
            })
          )
        }

      }

      // after all of the data is fetched, return it
      Promise.all(batches).then(content => {
        res(content);
      })

    })
  }

}
