import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-update-device',
  templateUrl: './update-device.component.html',
  styleUrls: ['./update-device.component.css']
})
export class UpdateDeviceComponent implements OnInit {
  device = {
    id: "",
    role: "",
    zoneAssigned: "",
    zoneAssigneds: []
  };
  zone1: any;
  zone2: any;
  zone3: any;
  zonelist = [];
  email = '';

  updateForm = false;
  constructor(
    private dialogRef: MatDialogRef<UpdateDeviceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private au: AuthService,
    private cd: ChangeDetectorRef,
    private toast: ToastrService
  ) {
    this.device = data;
    au.currentEmail.subscribe(e=> this.email = e);

    if(this.device.zoneAssigned){
      this.zone1 = this.device.zoneAssigned
    }
    else if (this.device.zoneAssigneds.length > 0){
      this.device.zoneAssigneds.forEach((e,index)=>{
        switch(index){
          case 0: this.zone1 = e;break;
          case 1: this.zone2 = e;break;
          case 2: this.zone3 = e;break;
        }
      })
    }



  }

  ngOnInit(): void {
    this.spinner.show();
    let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
      if(this.email === 'jppausm@vljejak.com')
        filter = ['USM MINDEN'];
    this.firestore.collection('zones',ref => ref.where('name','in',filter)).get().forEach(e => {
      e.forEach(zone => {
        const data = {
          ...zone.data(),
          id: zone.id
        }
        this.zonelist.push(data);
      })
      this.spinner.hide();
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {


    if (this.device.role === 'Inspector') {
      if (this.zone2 && this.zone3 && this.zone1) {
        if (this.zone1 === this.zone2 || this.zone1 === this.zone3 || this.zone2 === this.zone3) {
          this.toast.warning('Same zone has been choosed', 'Please check')
          return;
        }
      }
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Do you want to update this device ID? ' + this.device.id,
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.device.zoneAssigneds = [];
        this.spinner.show();
        if (this.zone1) {
          const zoneinfo = this.zonelist.find(z => z.name === this.zone1).name
          this.device.zoneAssigneds.push(zoneinfo);
          this.device.zoneAssigned = zoneinfo;
        }
        if (this.zone2 && this.device.role === 'Inspector') {
          const zoneinfo = this.zonelist.find(z => z.name === this.zone2).name

          this.device.zoneAssigneds.push(zoneinfo);
        }
        if (this.zone3 && this.device.role === 'Inspector') {
          const zoneinfo = this.zonelist.find(z => z.name === this.zone3).name

          this.device.zoneAssigneds.push(zoneinfo);

        }
        if(!this.zone1 && !this.zone2 && !this.zone3)
          this.device.zoneAssigned = null;
        if (this.device.role === 'Inspector') {
          this.device.zoneAssigned = null;
        } else {
          this.device.zoneAssigneds = [];
        }

        this.firestore.collection('devices').doc(this.device.id).update(this.device).then(r => {
          this.spinner.hide();

          this.dialogRef.close(true);
        });


      }
    });

  }



}
