import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private role = new BehaviorSubject<string>('');
  currentRole = this.role.asObservable();
  private email = new BehaviorSubject<string>('');
  currentEmail = this.email.asObservable();

  constructor() { }

  SetRole(role: string) {
    this.role.next(role);
  }

  SetEmail(email: string) {
    this.email.next(email);
  }

}
