import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Components/login/login.component';
import { LayoutComponent } from './Components/DashboardLayoutBase/layout/layout.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import {
  MatButtonModule,
  MatFormFieldModule,
  MatDialogModule,
  MatTableModule,
  MatMenuModule,
  MatSortModule,
  MatToolbarModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule,
  MatIconModule,
  MatRadioModule,
  MatTooltipModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatGridListModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatButtonToggleModule,
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TopnavComponent } from './Components/DashboardLayoutBase/topnav/topnav.component';
import { DeviceManagementComponent } from './Components/device-management/device-management.component';
import { MenuChosenPageComponent } from './Components/DashboardLayoutBase/menu-chosen-page/menu-chosen-page.component';
import { MonitorComponent } from './Components/monitor/monitor.component';
import { ReportComponent } from './Components/report/report.component';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { AddRouteComponent } from './Components/monitor/add-route/add-route.component';
import { PdfDatePickerComponent } from './shared/pdf-date-picker/pdf-date-picker.component';
import { PdfMonthPickerComponent } from './shared/pdf-date-picker/pdf-month-picker/pdf-month-picker.component';
import { DeviceInfoModalComponent } from './shared/device-info-modal/device-info-modal.component';
import { SpeedGraphComponent } from './shared/speed-graph/speed-graph.component';
import { MapComponent } from './Components/monitor/map/map.component';
import { TripSelectComponent } from './shared/trip-select/trip-select.component';
import { AduanComponent } from './Components/aduan/aduan.component';
import { MonitorDatePickerComponent } from './shared/monitor-date-picker/monitor-date-picker.component';
import { TripInfoComponent } from './shared/device-info-modal/trip-info/trip-info.component';
import { MapRouteComponent } from './shared/map-route/map-route.component';
import { AreaComponent } from './Components/area/area.component';
import { ManageLorryComponent } from './Components/device-management/manage-lorry/manage-lorry.component';
import { CreateZoneComponent } from './Components/area/create-zone/create-zone.component';
import { ListofstreetComponent } from './Components/area/create-zone/listofstreet/listofstreet.component';
import { DeviceKPAManagementComponent } from './Components/device-kpa-management/device-kpa-management.component';
import { PdfRangePickerComponent } from './shared/pdf-date-picker/pdf-range-picker/pdf-range-picker.component';
import { AssignDeviceComponent } from './Components/area/assign-device/assign-device.component';
import { RouteSelectComponent } from './shared/route-select/route-select.component';
import { KpaWebComponent } from './public/kpa-web/kpa-web.component';
import { PpsWebComponent } from './public/pps-web/pps-web.component';
import { QrPopUpComponent } from './Components/area/qr-pop-up/qr-pop-up.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { DashboardmapComponent } from './Components/dashboard/dashboardmap/dashboardmap.component';
import { PerformanceComponent } from './Components/performance/performance.component';
import { ZoneTableComponent } from './Components/report/zone-table/zone-table.component';
import { TableComponent } from './Components/device-kpa-management/table/table.component';
import { CreateDeviceComponent } from './Components/device-kpa-management/create-device/create-device.component';
import { SelectAndroidComponent } from './Components/device-kpa-management/table/select-android/select-android.component';
import { MonthPickerComponent } from './shared/month-picker/month-picker.component';
import { ContractorCardComponent } from './Components/performance/contractor-card/contractor-card.component';
import { OverallCardComponent } from './Components/performance/overall-card/overall-card.component';
import { MonthContractorCardComponent } from './Components/performance/month-contractor-card/month-contractor-card.component';
import { MonthOverallContractorCardComponent } from './Components/performance/month-overall-contractor-card/month-overall-contractor-card.component';
import { UpdateDeviceComponent } from './Components/device-kpa-management/update-device/update-device.component';
import { PdfMonthPickerSummaryComponent } from './shared/pdf-date-picker/pdf-month-picker-summary/pdf-month-picker-summary.component';

const config = {
  apiKey: 'AIzaSyCuI51l7XEMi1Q92q9InwHBXcL-TvWD9SU',
  authDomain: 'smart-waste-system.firebaseapp.com',
  databaseURL: 'https://smart-waste-system.firebaseio.com',
  projectId: 'smart-waste-system',
  storageBucket: 'smart-waste-system.appspot.com',
  messagingSenderId: '518813057682',
  appId: '1:518813057682:web:3f119f1a1665f7ee51dc4b',
  measurementId: 'G-ZP40TRS51D'
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    TopnavComponent,
    DeviceManagementComponent,
    MenuChosenPageComponent,
    MonitorComponent,
    ReportComponent,
    ConfirmationDialogComponent,
    AddRouteComponent,
    PdfDatePickerComponent,
    PdfMonthPickerComponent,
    DeviceInfoModalComponent,
    SpeedGraphComponent,
    MapComponent,
    TripSelectComponent,
    AduanComponent,
    MonitorDatePickerComponent,
    TripInfoComponent,
    MapRouteComponent,
    AreaComponent,
    ManageLorryComponent,
    CreateZoneComponent,
    ListofstreetComponent,
    DeviceKPAManagementComponent,
    PdfRangePickerComponent,
    AssignDeviceComponent,
    RouteSelectComponent,
    KpaWebComponent,
    PpsWebComponent,
    QrPopUpComponent,
    DashboardComponent,
    DashboardmapComponent,
    PerformanceComponent,
    ZoneTableComponent,
    TableComponent,
    CreateDeviceComponent,
    SelectAndroidComponent,
    MonthPickerComponent,
    ContractorCardComponent,
    OverallCardComponent,
    MonthContractorCardComponent,
    MonthOverallContractorCardComponent,
    UpdateDeviceComponent,
    PdfMonthPickerSummaryComponent,
  ],
  entryComponents:
  [
    ConfirmationDialogComponent,
    AddRouteComponent,
    PdfDatePickerComponent,
    PdfMonthPickerComponent,
    DeviceInfoModalComponent,
    SpeedGraphComponent,
    TripSelectComponent,
    MonitorDatePickerComponent,
    TripInfoComponent,
    MapRouteComponent,
    ManageLorryComponent,
    CreateZoneComponent,
    ListofstreetComponent,
    PdfRangePickerComponent,
    AssignDeviceComponent,
    RouteSelectComponent,
    QrPopUpComponent,
    CreateDeviceComponent,
    SelectAndroidComponent,
    UpdateDeviceComponent
  ],
  imports: [
    NgxSpinnerModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    MatSortModule,
    MatToolbarModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatRadioModule,
    MatTooltipModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    FormsModule,
    ToastrModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    HttpClientModule,
    DragDropModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy },AngularFirestore],
  bootstrap: [AppComponent]
})
export class AppModule { }
