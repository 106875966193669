import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, ElementRef, Input, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatPaginator } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboardmap',
  templateUrl: './dashboardmap.component.html',
  styleUrls: ['./dashboardmap.component.css']
})
export class DashboardmapComponent implements OnInit {

  @Input() zone: string;
  @Input() size: string;

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  selectedIndex = 1;
  speedGraph: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  isTracking = false;
  mobileQuery: MediaQueryList;
  imagelist = [];
  maplist = [];
  receiptlist = [];
  locatelist = [];
  savinglist = [];
  pathSavedlist = [];
  progress: any = 0;

  totalStreets : number;

  constructor( changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }
  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);

    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.GetKPAImage(new Date());
  }
  streets = [];
  savedMarkerlist = [];
  aduanlist = [];
  completedStreets : number;

  removeSavedPath() {
    this.pathSavedlist.forEach(e => {
      e.setMap(null);
    })
  }
  removeMarkerlist() {
    this.savedMarkerlist.forEach(e => {
      e.setMap(null);
    })
  }
  async GetKPAImage(date:Date) {
    var counter = 0;
    this.spinner.show();
    if (this.pathSavedlist.length > 0) {
      this.removeSavedPath();
    }
    if (this.savedMarkerlist.length > 0) {
      this.removeMarkerlist();
    }
    this.pathSavedlist = [];
    this.streets = [];
    this.savedMarkerlist = [];
    this.imagelist = [];
    this.aduanlist = [];
    var imageLength = 0;
    const check = date.getDay();
    await this.firestore.collection('streets',ref => ref.where('belongZone', '==', this.zone)).get().forEach(async streets => {
      let counter = 1;
      if (streets.size === 0) {
        this.spinner.hide();
      }
      await streets.forEach(streetdoc => {
        if (this.zone) {
          if (streetdoc.data().belongZone) {
            if (this.zone === streetdoc.data().belongZone) {
              if (this.getCheck(streetdoc.data(), check)) {
                this.streets.push(streetdoc.data().streetName);
                const paths = [];
                streetdoc.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                const path = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'red',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                });
                path.setMap(this.map);
                this.pathSavedlist.push(path);
                this.map.panTo(new google.maps.LatLng(streetdoc.data().centerCoordinate.latitude,
                streetdoc.data().centerCoordinate.longitude));

              }
            }
          }
        }


        if (counter === streets.size) {
        }
        counter++;

      });
    });
    this.totalStreets = this.streets.length;
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.firestore.collection('reports').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', 'kpa').where('zone','==',this.zone)).snapshotChanges().subscribe(result => {
        counter = 0;
        result.forEach(e => {
            let info = {};
            const r = this.imagelist.find(i => i.choosedWork === e.payload.doc.data().choosedWork &&
              i.choosedStreet === e.payload.doc.data().choosedStreet);
            if (r) {
              if (e.payload.doc.data().type === 'sebelum') {
                r.imagesebelum = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'semasa') {
                r.imagesemasa = e.payload.doc.data().image;
              } else if (e.payload.doc.data().type === 'selepas') {
                r.imageselepas = e.payload.doc.data().image;
              }
            } else {
              if (e.payload.doc.data().type === 'sebelum') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: e.payload.doc.data().image,
                  imageselepas: '../../../assets/notyet.png',
                  imagesemasa: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'semasa') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imagesemasa: e.payload.doc.data().image,
                  imageselepas: '../../../assets/notyet.png',
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              } else if (e.payload.doc.data().type === 'selepas') {
                info = {
                  map: this.map,
                  id: e.payload.doc.data().deviceId,
                  date: e.payload.doc.data().date,
                  imagesebelum: '../../../assets/notyet.png',
                  imagesemasa: '../../../assets/notyet.png',
                  imageselepas: e.payload.doc.data().image,
                  choosedStreet: e.payload.doc.data().choosedStreet,
                  flag: false,
                  choosedWork: e.payload.doc.data().choosedWork,
                };
                this.imagelist.push(info);

              }
            }

        });

        this.imagelist = this.imagelist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        this.spinner.hide();

        if(imageLength === 0) {
          imageLength = this.imagelist.length;
        }else if(imageLength === this.imagelist.length){
          return;
        }
        this.removeMarkerlist();
        console.log(this.streets)

        this.imagelist.forEach(async (e,index) => {
          await this.firestore.collection('streets', ref => ref.where('streetName', '==', e.choosedStreet)).get().forEach(s => {
            s.forEach(document => {
              var ind = this.streets.findIndex(a => a === document.data().streetName);

              if (ind > -1) {
                if(e.imagesebelum === '../../../assets/notyet.png' || e.imageselepas === '../../../assets/notyet.png'  || e.imagesemasa === '../../../assets/notyet.png'){
                  return;
                }
                if(index >= 1){
                  if(e.choosedStreet === this.imagelist[index-1].choosedStreet && this.pathSavedlist[ind].status){
                    return;
                  }
                }

                e.position = new google.maps.LatLng(document.data().centerCoordinate.latitude,
                  document.data().centerCoordinate.longitude);
                const marker = new google.maps.Marker({
                  ...e,
                  icon: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
                });
                marker.setMap(this.map);
                this.savedMarkerlist.push(marker);
                this.map.setCenter(marker.getPosition());
                const info =
                {
                  status : true,
                }
                const paths = [];
                document.data().coordinates.forEach(coor => {
                  paths.push(new google.maps.LatLng(coor.latitude, coor.longitude));
                });
                this.pathSavedlist[ind].setMap(null);
                this.pathSavedlist[ind] = new google.maps.Polygon({
                  paths,
                  strokeColor: 'black',
                  fillColor: 'green',
                  strokeOpacity: 1.0,
                  strokeWeight: 2,
                  ...info

                });

                this.pathSavedlist[ind].setMap(this.map);
                counter++;
                this.completedStreets = counter;
                this.progress = (this.completedStreets/this.totalStreets * 100).toFixed(2)

              }

            });
          });

        });
      });


  }


  getCheck(street, day) {
    if(day === 0){
      day = 7;
    }
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  getColor(value) {
    if (value > 66) {
      return "#5fe2a0";
    } else if (value > 33) {
      return "#ffed06"
    } else {
      return "#f53c56"
    }
  }


}
