import { PerformanceComponent } from './Components/performance/performance.component';
import { DashboardComponent } from './Components/dashboard/dashboard.component';
import { DeviceKPAManagementComponent } from './Components/device-kpa-management/device-kpa-management.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './Components/login/login.component';
import { LayoutComponent } from './Components/DashboardLayoutBase/layout/layout.component';
import { DeviceManagementComponent } from './Components/device-management/device-management.component';
import { MenuChosenPageComponent } from './Components/DashboardLayoutBase/menu-chosen-page/menu-chosen-page.component';
import { MonitorComponent } from './Components/monitor/monitor.component';
import { ReportComponent } from './Components/report/report.component';
import { AduanComponent } from './Components/aduan/aduan.component';
import { AreaComponent } from './Components/area/area.component';
import { PpsWebComponent } from './public/pps-web/pps-web.component';
import { KpaWebComponent } from './public/kpa-web/kpa-web.component';


const routes: Routes = [{
  path: '',
  redirectTo: '/login',
  pathMatch: 'full',
},
{ path: 'login', component: LoginComponent },
{ path: 'PpsWeb/:id', component: PpsWebComponent },
{ path: 'KpaWeb/:id', component: KpaWebComponent },

{ path: 'menu', component: MenuChosenPageComponent },

{ path: 'home', component: LayoutComponent,

  children: [
    {
      path: 'monitor',
      component: MonitorComponent
    },
    {
      path: 'dashboard',
      component: DashboardComponent
    },
    {
      path: 'report',
      component: ReportComponent
    },
    {
      path: 'device',
      component: DeviceManagementComponent
    },
    {
      path: 'devicekpa',
      component: DeviceKPAManagementComponent
    },
    {
      path: 'area',
      component: AreaComponent
    },
    {
      path: 'aduan',
      component: AduanComponent
    },
    {
      path: 'performance',
      component: PerformanceComponent
    },

  ]
},
// {
//   path: '**',
//   redirectTo: '/login'
// }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
