import { TableComponent } from './table/table.component';
import { CreateDeviceComponent } from './create-device/create-device.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ManageLorryComponent } from '../device-management/manage-lorry/manage-lorry.component';
import { DateService } from '../Services/date.service';
import { FirestoreServiceService } from '../Services/firestore-service.service';
import { AuthService } from '../Services/auth.service';

@Component({
  selector: 'app-device-kpa-management',
  templateUrl: './device-kpa-management.component.html',
  styleUrls: ['./device-kpa-management.component.css']
})
export class DeviceKPAManagementComponent implements OnInit {

  devicelist = [];
  sorteddevicelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };

  zones = [];

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;
  email = '';

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private au: AuthService,
  ) {
    au.currentEmail.subscribe(e=> {
      this.email = e
      console.log(e)
    }
      );
    console.log(this.email)
   }

  ngOnInit() {
    this.spinner.show();
    this.SetupZone();
  }

  SetupZone() {
    let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
    if(this.email === 'jppausm@vljejak.com')
      filter = ['USM MINDEN'];


    this.firestore.collection('zones', ref => ref.where('name','in',filter)).get().forEach(e => {
      e.forEach(zone => {
        const data = {
          ...zone.data(),
          id: zone.id
        }
        this.zones.push(data);
      })
    });
  }

  @ViewChild('TableComponent1', { static: false }) table1: TableComponent;
  @ViewChild('TableComponent2', { static: false }) table2: TableComponent;
  @ViewChild('TableComponent3', { static: false }) table3: TableComponent;


  CreateDevice() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data=this.zones;
    this.dialog.open(CreateDeviceComponent, dialogConfig).afterClosed().subscribe(result => {
      this.table1.SetupTable();
      this.table2.SetupTable();
      this.table3.SetupTable();

    });
  }


}
