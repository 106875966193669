import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  hidePasswordR = true;
  hideConfirmPasswordR = true;


  loginModel: any = {};
  registerModel: any = {};
  flag = true;
  validationsMessages = ' ';
  today = Date.now();
  breakpoint: boolean;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
  ) { }

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  changeForm() {
    this.flag = !this.flag;
  }

  login() {
    const email = this.loginModel.email;
    const password = this.loginModel.password;
    this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(res => {

        this.toast.success('Welcome to Smart Waste Management System', 'Login Successfully !', { timeOut: 3000 });
        localStorage.setItem('Role', 'KPA');
        this.router.navigate(['home/monitor']);

      })
      .catch(err => {
        this.validationsMessages = 'Invalid email or password.';
      });
  }



}
