import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import * as jsPdf from 'jspdf';
import { MatDialogConfig, MatDialog, MatCalendarBody } from '@angular/material';
import { PdfDatePickerComponent } from 'src/app/shared/pdf-date-picker/pdf-date-picker.component';
import { AngularFireStorage } from '@angular/fire/storage';
import 'jspdf-autotable';
import { AngularFirestore } from '@angular/fire/firestore';
import { finalize } from 'rxjs/operators';
const FileSaver = require('file-saver');
import { HttpClient } from '@angular/common/http';
import { PDFDocument, PDFName, PDFPage } from 'pdf-lib'
import { SSL_OP_ALL } from 'constants';
import { AuthService } from '../Services/auth.service';
const download = require("downloadjs");
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  selectedOptions: any = ['Bagan'];
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'October'];
  role: any;
  zonelist = [];
  selectedZone: any;
  WeightGraph: any;
  Title: any;
  email = '';

  constructor(private dialog: MatDialog,
    private storageRef: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private au: AuthService,
    private http: HttpClient,
    private toast: ToastrService
  ) {
    this.role = localStorage.getItem('Role');
    if (this.role === 'KPA') {
      this.Title = 'All';
    }
    au.currentEmail.subscribe(e=> this.email = e);

  }

  onNgModelChange(event) {

  }
  ngOnInit() {
    this.spinner.show()
    let filter = ['Bagan Jermal', 'Sungai Bakap','USM MINDEN'];
      if(this.email === 'jppausm@vljejak.com')
        filter = ['USM MINDEN'];

    this.firestore.collection('zones',ref => ref.where('name','in',filter)).get().forEach(e => {
      this.spinner.hide()
      e.forEach(doc => {
        this.zonelist.push(doc.data());
      })
      this.zonelist = this.zonelist.sort((a,b)=> {return (a.name < b.name ? -1 : 1) })

    })
  }

  showZone(zone) {
    this.selectedZone = zone.name;
    this.Title = this.selectedZone;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  async getZone(street: string): Promise<any> {
    var returnedData = this.firestore.collection('streets',
      ref => ref.where('streetName', '==', street)).get().toPromise();
    return returnedData;
  }

  async getImage(dateString): Promise<any> {

    var returnedData = await this.firestore.collection('reports').doc(dateString).
      collection('reports', ref => ref.where('mode', '==', 'kpa').where('zone', '==', this.selectedZone).orderBy('deviceId', 'asc'))
      .get().toPromise();
    returnedData.docs.forEach(async report => {
      let info = {};
      const r = this.imagelist.find(i => i.choosedWork === report.data().choosedWork &&
        i.choosedStreet === report.data().choosedStreet);
      if (r) {
        if (report.data().type === 'sebelum') {
          r.imagesebelum = report.data().image;
          r.datesebelum = new Date(report.data().date.toDate());
        } else if (report.data().type === 'semasa') {
          r.imagesemasa = report.data().image;
          r.datesemasa = new Date(report.data().date.toDate());
        } else if (report.data().type === 'selepas') {
          r.imageselepas = report.data().image;
          r.dateselepas = new Date(report.data().date.toDate());
        }
      } else {
        if (report.data().type === 'sebelum') {
          info = {
            // id: report.data().deviceId,
            datesebelum: new Date(report.data().date.toDate()),
            datesemasa: '',
            dateselepas: '',
            imagesebelum: report.data().image,
            imageselepas: '../../../assets/notyet.png',
            imagesemasa: '../../../assets/notyet.png',
            choosedStreet: report.data().choosedStreet,
            choosedWork: report.data().choosedWork,
          };
          this.imagelist.push(info);

        } else if (report.data().type === 'semasa') {
          info = {
            // id: report.data().deviceId,
            datesebelum: '',
            datesemasa: new Date(report.data().date.toDate()),
            dateselepas: '',
            imagesebelum: '../../../assets/notyet.png',
            imagesemasa: report.data().image,
            imageselepas: '../../../assets/notyet.png',
            choosedStreet: report.data().choosedStreet,
            choosedWork: report.data().choosedWork,
          };
          this.imagelist.push(info);
        } else if (report.data().type === 'selepas') {
          info = {
            // id: report.data().deviceId,
            datesebelum: '',
            datesemasa: '',
            dateselepas: new Date(report.data().date.toDate()),
            imagesebelum: '../../../assets/notyet.png',
            imagesemasa: '../../../assets/notyet.png',
            imageselepas: report.data().image,
            choosedStreet: report.data().choosedStreet,
            choosedWork: report.data().choosedWork,
          };
          this.imagelist.push(info);
        }
      }
    })
    return this.imagelist;
  }

  imagelist = [];


  async savePdfDay(date) {
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.firestore.collection('KPA_Report', ref => ref.where('date', '==', dateString).where('zone', '==', this.selectedZone)).get().forEach(async report => {
      if (!report.empty) {
        report.forEach(data => {
          const pdfUrl = data.data().pdfURL;
          FileSaver.saveAs(pdfUrl, dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf');
        })

      } else {
        this.imagelist = [];
        this.spinner.show();
        this.imagelist = await this.getImage(dateString);
        this.imagelist = await this.imagelist.sort((a, b) => {
          return this.compare(a.choosedStreet, b.choosedStreet, true);
        })
        if(this.imagelist.length === 0){
          this.toast.error('Tidak ada data')
          this.spinner.hide();
          return;
        }

        this.http.post('https://kpa-api-ghccr47q7q-as.a.run.app/GetReportDay',
          {
            "date": dateString,
            "datestring": date.toDateString(),
            "imagelist": this.imagelist,
            "zone": this.selectedZone
          }).subscribe((response: any) => {
            if (response.status_code === 200) {
              const info = {
                pdfURL: response.Url,
                zone: this.selectedZone,
                date: dateString,
              }

              const y = new Date().getFullYear();
              const d = ('0' + new Date().getDate()).slice(-2);
              const m = ('0' + (new Date().getMonth() + 1)).slice(-2);
              const ds = y + m + d;

              if (dateString !== ds) {
                this.firestore.collection('KPA_Report').add(info);
              }

              FileSaver.saveAs(info.pdfURL, dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf');
              this.spinner.hide();
            }
          },
          error=>{
            this.toast.error('Unexpected Error hapeen, please contact admin')
            this.spinner.hide();
          })



        // const doc = new jsPdf();
        // doc.setFontSize(32);
        // doc.setFontType('bold');
        // doc.text('LAPORAN BERGAMBAR', 35, 80);
        // doc.text('(' + this.selectedZone + ')', 35, 100);
        // doc.setFontSize(28);
        // doc.setFontType('bold');
        // doc.text('Date: ' + date.toDateString(), 35, 120);
        // doc.setFontType('normal');
        // this.spinner.hide();
        // let counter = 0;
        // this.imagelist.forEach(device => {
        //   if (device.datesebelum === '' || device.datesemasa === '' || device.dateselepas === '') {

        //   } else {
        //     if (counter % 2 === 0) {
        //       doc.addPage();
        //       doc.setFontSize(12);
        //       let splitTitle = doc.splitTextToSize('Lokasi :' + device.choosedStreet, 70);
        //       doc.text(splitTitle, 30, 20);
        //       doc.text('Activiti :' + device.choosedWork, 30, 30);
        //       doc.text('Sebelum', 30, 35);
        //       doc.text('Semasa', 30, 120);
        //       doc.text('Selepas', 30, 205);
        //       let img = document.createElement('img');
        //       img.src = device.imagesebelum;
        //       doc.addImage(img, 'JPEG', 30, 38, 70, 70, undefined, 'FAST');
        //       img.src = device.imagesemasa;
        //       doc.addImage(img, 'JPEG', 30, 123, 70, 70, undefined, 'FAST');
        //       img.src = device.imageselepas;
        //       doc.addImage(img, 'JPEG', 30, 208, 70, 70, undefined, 'FAST');
        //     } else {

        //       let splitTitle = doc.splitTextToSize('Lokasi:' + device.choosedStreet, 70);
        //       doc.text(splitTitle, 110, 20);
        //       doc.text('Activity :' + device.choosedWork, 110, 30);
        //       doc.text('Sebelum', 110, 35);
        //       doc.text('Semasa', 110, 120);
        //       doc.text('Selepas', 110, 205);
        //       let img = document.createElement('img');
        //       img.src = device.imagesebelum;
        //       doc.addImage(img, 'JPEG', 110, 38, 70, 70, undefined, 'FAST');
        //       img.src = device.imagesemasa;
        //       doc.addImage(img, 'JPEG', 110, 123, 70, 70, undefined, 'FAST');
        //       img.src = device.imageselepas;
        //       doc.addImage(img, 'JPEG', 110, 208, 70, 70, undefined, 'FAST');
        //     }
        //     counter++;
        //   }
        // })
        // const y = new Date().getFullYear();
        // const d = ('0' + new Date().getDate()).slice(-2);
        // const m = ('0' + (new Date().getMonth() + 1)).slice(-2);
        // const ds = y + m + d;

        // if (dateString === ds) {
        //   doc.save(dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf');
        //   this.spinner.hide();
        // } else {
        //   const file = doc.output("blob");
        //   const fileRef = this.storageRef.ref('KPA_Report/PDF/' + dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf');
        //   const task = this.storageRef.upload('KPA_Report/PDF/' + dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf', file);
        //   task.snapshotChanges().pipe(
        //     finalize(() => {
        //       var downloadURL = fileRef.getDownloadURL();
        //       downloadURL.subscribe(url => {
        //         if (url) {
        //           const pdfURL = url;
        //           const info = {
        //             pdfURL,
        //             zone: this.selectedZone,
        //             date: dateString,
        //           }
        //           this.firestore.collection('KPA_Report').add(info);
        //           doc.save(dateString + '_' + this.selectedZone.replaceAll(' ', '') + '_KPA_Report.pdf');
        //           this.spinner.hide();
        //         }
        //       });
        //     }
        //     )
        //   ).subscribe(url => { });
        // }

      }
    })

  }

  async savePdfWeek(dateFrom, dateTo) {
    const lastDay = new Date(dateTo);
    const firstDay = new Date(dateFrom);
    var Difference_In_Time = lastDay.getTime() - firstDay.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    this.imagelist = [];
    this.spinner.show();
    const doc = new jsPdf();
    for (let i = 0; i <= Difference_In_Days; i++) {
      const year = firstDay.getFullYear();
      const day = ('0' + firstDay.getDate()).slice(-2);
      const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
      const dateString = year + month + day;
      this.imagelist = await this.getImage(dateString);
      this.imagelist = this.imagelist.sort((a, b) => {
        return this.compare(a.choosedStreet, b.choosedStreet, true);
      })
      doc.setFontSize(32);
      doc.setFontType('bold');
      doc.text('LAPORAN BERGAMBAR', 35, 80);
      doc.text('(' + this.Title + ')', 35, 100);
      doc.setFontSize(28);
      doc.setFontType('bold');
      doc.text('Date: ' + firstDay.toDateString(), 35, 120);
      doc.setFontType('normal');
      let counter = 0;
      this.imagelist.forEach(device => {
        if (device.datesebelum === '' || device.datesemasa === '' || device.dateselepas === '') {
        } else {
          if (counter % 2 === 0) {
            doc.addPage();
            doc.setFontSize(12);
            const splitTitle = doc.splitTextToSize('Lokasi :' + device.choosedStreet, 70);
            doc.text(splitTitle, 30, 20);
            doc.text('Activiti :' + device.choosedWork, 30, 30);
            doc.text('Sebelum', 30, 35);
            doc.text('Semasa', 30, 120);
            doc.text('Selepas', 30, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 30, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 30, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 30, 208, 70, 70);

            doc.setTextColor(0, 0, 0);

          } else {
            const splitTitle = doc.splitTextToSize('Lokasi:' + device.choosedStreet, 70);
            const splitTitleimg = doc.splitTextToSize(device.choosedStreet, 40);

            doc.text(splitTitle, 110, 20);
            doc.text('Activity :' + device.choosedWork, 110, 30);
            doc.text('Sebelum', 110, 35);
            doc.text('Semasa', 110, 120);
            doc.text('Selepas', 110, 205);
            const imagesebelum = document.createElement('img');
            const imageselepas = document.createElement('img');
            const imagesemasa = document.createElement('img');

            imagesebelum.src = device.imagesebelum;
            imageselepas.src = device.imageselepas;
            imagesemasa.src = device.imagesemasa;
            doc.addImage(imagesebelum, 'PNG', 110, 38, 70, 70);
            doc.setTextColor(255, 255, 255);
            doc.addImage(imagesemasa, 'PNG', 110, 123, 70, 70);
            doc.addImage(imageselepas, 'PNG', 110, 208, 70, 70);
            doc.setTextColor(0, 0, 0);

          }
          counter++;
        }
      })
      doc.addPage();

      firstDay.setDate(firstDay.getDate() + 1);
      if (i === (Difference_In_Days)) {
        this.spinner.hide();
      }

    }
    doc.save(dateFrom.toDateString() + '_KPA_Report_Range.pdf');

  }

  getPDf(ids): Promise<any> {
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length) return res([]);

      let batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);
        // add the batch request to to a queue
        batches.push(
          new Promise(response => {
            this.firestore.collection('KPA_Report', ref => ref.where('date', 'in', batch).where('zone', '==', this.selectedZone))
              .get().forEach(results => {
                response(results.docs.map(result => ({ ...result.data() })))
              })
          })
        )
      }

      // after all of the data is fetched, return it
      Promise.all(batches).then(content => {
        res(content);
      })

    })
  }

  getPDfSummary(ids): Promise<any> {
    return new Promise((res) => {
      // don't run if there aren't any ids or a path for the collection
      if (!ids || !ids.length) return res([]);

      let batches = [];

      while (ids.length) {
        // firestore limits batches to 10
        const batch = ids.splice(0, 10);
        // add the batch request to to a queue
        batches.push(
          new Promise(response => {
            this.firestore.collection('KPA_SummaryDailyReport', ref => ref.where('date', 'in', batch).where('zone', '==', this.selectedZone))
              .get().forEach(results => {
                response(results.docs.map(result => ({ ...result.data() })))
              })
          })
        )
      }

      // after all of the data is fetched, return it
      Promise.all(batches).then(content => {
        res(content);
      })

    })
  }

  async savePdfMonth(date) {
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    this.spinner.show();

    const totalDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const arrayDate = [];
    for (let i = 0; i < totalDay; i++) {
      const year = firstDay.getFullYear();
      const day = ('0' + firstDay.getDate()).slice(-2);
      const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
      arrayDate.push(year + month + day);
      firstDay.setDate(firstDay.getDate() + 1);
    }

    const result = await this.getPDf(arrayDate);
    let sourceFile = [];
    await result.forEach(async element => {
      await element.forEach(element => {
        sourceFile.push(element)
      });
    });

    const documentString = this.selectedZone + this.months[month - 1] + year
    this.firestore.collection('KPA_Report_Month').doc(documentString).get().forEach(doc => {
      if (doc.exists) {

        const days = doc.data().Days
        if (days.length === sourceFile.length) {
          const url = doc.data().Url;
          FileSaver.saveAs(url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
          this.spinner.hide();
        } else {
          this.http.post('https://kpa-api-ghccr47q7q-as.a.run.app/GetReport',
            {
              "month": month,
              "year": year,
              "zone": this.selectedZone
            }).subscribe((response: any) => {
              if (response.status_code === 200) {
                const info = {
                  Url: response.Url,
                  zone: this.selectedZone,
                  month: month,
                  year: year,
                  Days: response.Days
                }
                this.firestore.collection('KPA_Report_Month').doc(this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + year).set(info);
                FileSaver.saveAs(info.Url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
                this.spinner.hide();
              } },
              error=>{
                this.toast.error('Unexpected Error hapeen, please contact admin')
                this.spinner.hide();
              })
        }
      } else {
        this.http.post('https://kpa-api-ghccr47q7q-as.a.run.app/GetReport',
          {
            "month": month,
            "year": year,
            "zone": this.selectedZone
          }).subscribe((response: any) => {
            if (response.status_code === 200) {
              const info = {
                Url: response.Url,
                zone: this.selectedZone,
                month: month,
                year: year,
                Days: response.Days
              }
              this.firestore.collection('KPA_Report_Month').doc(this.selectedZone + this.months[month - 1] + year).set(info);
              FileSaver.saveAs(info.Url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
              this.spinner.hide();
            } },
            error=>{
              this.toast.error('Unexpected Error hapeen, please contact admin')
              this.spinner.hide();
            })
      }
    })

  }
  async savePdfMonthSummary(date) {
    const month = new Date(date).getMonth() + 1;
    const year = new Date(date).getFullYear();
    this.spinner.show();

    const totalDay = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const arrayDate = [];
    for (let i = 0; i < totalDay; i++) {
      const year = firstDay.getFullYear();
      const day = ('0' + firstDay.getDate()).slice(-2);
      const month = ('0' + (firstDay.getMonth() + 1)).slice(-2);
      arrayDate.push(year + month + day);
      firstDay.setDate(firstDay.getDate() + 1);
    }

    const result = await this.getPDfSummary(arrayDate);
    let sourceFile = [];
    await result.forEach(async element => {
      await element.forEach(element => {
        sourceFile.push(element)
      });
    });

    const documentString = this.selectedZone + this.months[month - 1] + year
    this.firestore.collection('KPA_SummaryDailyReport_Month').doc(documentString).get().forEach(doc => {
      if (doc.exists) {
        const days = doc.data().Days

        if (days.length === sourceFile.length) {
          const url = doc.data().Url;

          FileSaver.saveAs(url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
          this.spinner.hide();
        } else {
          this.http.post('https://kpa-api-ghccr47q7q-as.a.run.app/GetReportSummary',
            {
              "month": month,
              "year": year,
              "zone": this.selectedZone
            }).subscribe((response: any) => {
              if (response.status_code === 200) {
                const info = {
                  Url: response.Url,
                  zone: this.selectedZone,
                  month: month,
                  year: year,
                  Days: response.Days
                }
                this.firestore.collection('KPA_SummaryDailyReport_Month').doc(this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + year).set(info);
                FileSaver.saveAs(info.Url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
                this.spinner.hide();
              } },
              error=>{
                this.toast.error('Unexpected Error hapeen, please contact admin')
                this.spinner.hide();
              })
        }
      } else {
        this.http.post('https://kpa-api-ghccr47q7q-as.a.run.app/GetReportSummary',
          {
            "month": month,
            "year": year,
            "zone": this.selectedZone
          }).subscribe((response: any) => {
            if (response.status_code === 200) {
              const info = {
                Url: response.Url,
                zone: this.selectedZone,
                month: month,
                year: year,
                Days: response.Days
              }
              this.firestore.collection('KPA_SummaryDailyReport_Month').doc(this.selectedZone + this.months[month - 1] + year).set(info);
              FileSaver.saveAs(info.Url, this.selectedZone.replaceAll(' ', '') + this.months[month - 1] + 'KPA_Report.pdf');
              this.spinner.hide();
            }
          },
          error=>{
            this.toast.error('Unexpected Error hapeen, please contact admin')
            this.spinner.hide();
          })
      }
    })

  }


  openPicker() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.zonelist;
    this.dialog.open(PdfDatePickerComponent, dialogConfig).afterClosed().subscribe(async (result) => {
      if (result) {
        this.selectedZone = result.zone;
        switch (result.type) {
          case 'day':
            this.savePdfDay(result.date);
            break;
          case 'week':
            this.savePdfWeek(result.dateFrom, result.dateTo);
            break;
          case 'month':
            if(result.report === 'summary')
              this.savePdfMonthSummary(result.date);
            else
              this.savePdfMonth(result.date);
            break;
        }
      }
    });

  }


}
