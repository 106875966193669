import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatStepper } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-listofstreet',
  templateUrl: './listofstreet.component.html',
  styleUrls: ['./listofstreet.component.css']
})
export class ListofstreetComponent implements OnInit {

  streetlist: any;
  constructor(
    private dialogRef: MatDialogRef<ListofstreetComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private formBuilder: FormBuilder,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService,

  ) {

    this.streetlist = data;
  }
  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

}
