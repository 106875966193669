import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-month-contractor-card',
  templateUrl: './month-contractor-card.component.html',
  styleUrls: ['./month-contractor-card.component.css']
})
export class MonthContractorCardComponent implements OnInit {

  @Input() zone: any;
  @Input() date: Date;
  aduanGraph: Chart;
  pemantuanGraph: Chart;
  values = {
    totalAduan: 0,
    totalAduanCompleted: 0,
    totalPemantauan: 0,
    totalPemantauanCompleted: 0,
    totalCompleted: 0,
    totalStreet: 0,
    RespondTime: 0,
  };
  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.getZoneInfo(this.zone);

  }

  ngOnChanges(changes: SimpleChanges) {
    this.getZoneInfo(this.zone);
  }

  fakeArray=[];
  async getZoneInfo(zone) {
    this.values = {
      totalAduan: 0,
      totalAduanCompleted: 0,
      totalPemantauan: 0,
      totalPemantauanCompleted: 0,
      totalCompleted: 0,
      totalStreet: 0,
      RespondTime: 0,
    }
    this.spinner.show();
    if (!this.date)
      this.date = new Date();

    const d = new Date(this.date);
    this.fakeArray = new Array(new Date(d.getFullYear(), d.getMonth()+1, 0).getDate());

    for (let index = 0; index < this.fakeArray.length; index++) {
      let day = d.getDay();
      if (day === 0)
        day = 7;
        await this.firestore.collection('streets', ref => ref.where('belongZone', '==', zone)).get().forEach(async streets => {
          await streets.forEach(streetdoc => {
          this.getCheck(streetdoc.data(), day);
        });
        this.spinner.hide();
      });

      const days = new Date( d.getFullYear(), d.getMonth(), index+1);
      if(days.getDate() > new Date().getDate()
      && days.getMonth() === new Date().getMonth()
      && days.getFullYear() === new Date().getFullYear()){
        continue;
      }
      this.check(days);
    }

    this.getAduan(this.zone, d);
    this.getCompleted(this.zone, d);
  }




  getAduan(zone, d) {
    var month = d.getMonth(),
    year = d.getFullYear();

    // GET THE FIRST AND LAST DATE OF THE MONTH.
    var date = new Date(year, month, 1);
    date.setHours(0, 0, 0);
    var nextDate = new Date(year, month + 1, 0);

    this.firestore.collection('inspects', ref => ref.where('belongZone', '==', zone).where('date', '>=', date).where('date', '<=', nextDate)).get().forEach(async aduan => {
      await aduan.forEach(doc => {
        if (doc.data().category = 'aduan')
          this.values.totalAduan++;
        else
          this.values.totalPemantauan++;
      });
      this.spinner.hide();
    });
  }

  getCompleted(zone, d) {
    var month = d.getMonth(),
    year = d.getFullYear();

    // GET THE FIRST AND LAST DATE OF THE MONTH.
    var date = new Date(year, month, 1);
    date.setHours(0, 0, 0);
    var nextDate = new Date(year, month + 1, 0);

    this.firestore.collection('inspectArchives', ref => ref.where('belongZone', '==', zone).where('date', '>=', date).where('date', '<=', nextDate)).get().forEach(async aduan => {
      await aduan.forEach(doc => {
        if (doc.data().category = 'aduan') {
          this.values.totalAduan++;
          this.values.totalAduanCompleted++;
        }
        else {
          this.values.totalPemantauan++;
          this.values.totalPemantauanCompleted++;
        }
      });
      this.spinner.hide();
    });
  }

  imagelist = [];

  getCheck(street, day) {
    street.dayAvailables.forEach(element => {
      if (day === element)
        this.values.totalStreet++;
    });
  }

  getColor(value1, value2) {
    if (value1 === 0 || !value1)
      return "none";

    const value = value1 / value2 * 100;
    if (value < 33) {
      return "orangered";
    } else if (value < 66) {
      return "#f28d06"
    } else {
      return "rgb(39 197 98)"
    }

  }
  getColor2(value1) {
    if (value1 === 0 || !value1)
      return "none";

    return "rgb(39 197 98)"
  }

  check(date){
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;

    this.firestore.collection('reports').doc(dateString).get().forEach(r=>{
      const re = r.data()['Completed' + this.zone];
      if((re || re === 0 )&& (
        date.getDate() !== new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
        )){
        this.values.totalCompleted += r.data()['Completed' + this.zone];
      }else{
        this.firestore.collection('reports').doc(dateString).
        collection('reports', ref => ref.where('mode', '==', 'kpa').where('zone','==',this.zone)).get().forEach(result => {
          var counter = 0;
          this.imagelist = [];
          result.forEach(e => {
              let info = {};
              const r = this.imagelist.find(i => i.choosedWork === e.data().choosedWork &&
                i.choosedStreet === e.data().choosedStreet);
              if (r) {
                if (e.data().type === 'sebelum') {
                  r.imagesebelum = e.data().image;
                } else if (e.data().type === 'semasa') {
                  r.imagesemasa = e.data().image;
                } else if (e.data().type === 'selepas') {
                  r.imageselepas = e.data().image;
                }
              } else {
                if (e.data().type === 'sebelum') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'semasa') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: e.data().image,
                    imageselepas: '../../../assets/notyet.png',
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);

                } else if (e.data().type === 'selepas') {
                  info = {
                    id: e.data().deviceId,
                    date: e.data().date,
                    imagesebelum: '../../../assets/notyet.png',
                    imagesemasa: '../../../assets/notyet.png',
                    imageselepas: e.data().image,
                    choosedStreet: e.data().choosedStreet,
                    flag: false,
                    choosedWork: e.data().choosedWork,
                  };
                  this.imagelist.push(info);
                }
              }

          });

          this.imagelist = this.imagelist.sort((a, b) => {
            return this.compare(a.choosedStreet, b.choosedStreet, true);
          })
          this.spinner.hide();

          this.imagelist.forEach(async (e,index) => {
            if(e.imagesebelum === '../../../assets/notyet.png' || e.imageselepas === '../../../assets/notyet.png'  || e.imagesemasa === '../../../assets/notyet.png'){
              return;
            }
            if(index > 1){
              if(e.choosedStreet === this.imagelist[index-1].choosedStreet){
                return;
              }
            }
            counter++;
          });
          const completed={}
          this.values.totalCompleted += counter;
          completed['Completed'+ this.zone] = counter
          this.firestore.collection('reports').doc(dateString).update(completed);

        });
      }
    })


  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  view() {

  }
  getColors(value) {
    if (value > 66) {
      return "#5fe2a0";
    } else if (value > 33) {
      return "#ffed06"
    } else {
      return "#f53c56"
    }
  }
  ngAfterViewInit(): void {
    this.initializeGraph();

  }
  initializeGraph(){

    if(this.aduanGraph){
      this.aduanGraph.destroy()
    }

    if(this.pemantuanGraph){
      this.pemantuanGraph.destroy()
    }

    this.aduanGraph = new Chart('pieAduan1'+ this.zone.replaceAll(' ',''), {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: ["Aduan Selesai", "Aduan Tak Selesai"],
        datasets: [{
          label: "Aduan",
          backgroundColor: ['#0000FF', '#0080FF'],
          data: [5,1],
        }]
      },
      options: {
        maintainAspectRatio:false,
        legend: {
          position: 'bottom'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(value === 0)
                return "";
              return value;
            },
            color: '#fff',
          }
        }
      }
    })

    this.pemantuanGraph = new Chart('piePemantuan1'+ this.zone.replaceAll(' ',''), {
      plugins: [ChartDataLabels],
      type: 'doughnut',
      data: {
        labels: ["Pemantuan Selesai", "Pemantuan Tak Selesai"],
        datasets: [{
          label: "Aduan",
          backgroundColor: ['#00994C', '#00CC66'],
          data: [5,1],
        }]
      },
      options: {
        maintainAspectRatio:false,
        legend: {
          position: 'bottom'
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              if(value === 0)
                return "";
              return value;
            },
            color: '#fff',
          }
        }
      }
    })



  }

}
